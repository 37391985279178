@font-face {
    font-family: 'SUIT';
    src: url(./fonts/SUIT-Medium.ttf);
  }
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'SUIT';
    color: #333;
    text-align: center;
}

a {
    text-decoration: none;
    cursor: pointer;
}

ol,ul {
    list-style: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

::selection {
    background-color: #333;
    color: #fff;
}

.cursor {
    width: 30px;
    height: 30px;
    margin: -15px;
    background-color: aliceblue;
    position: fixed;
    border-radius: 50%;
    z-index: 99999;
    pointer-events: none;
    mix-blend-mode: difference;
}

::-webkit-scrollbar { width: 10px; } /* 스크롤 바 */

::-webkit-scrollbar-track { background-color:#fff; } /* 스크롤 바 밑의 배경 */

::-webkit-scrollbar-thumb { background: #ddd; border-radius: 10px; transition: all .2s; } /* 실질적 스크롤 바 */

::-webkit-scrollbar-thumb:hover { background: #aaa; } /* 실질적 스크롤 바 위에 마우스를 올려다 둘 때 */

::-webkit-scrollbar-thumb:active { background: #888; } /* 실질적 스크롤 바를 클릭할 때 */

::-webkit-scrollbar-button { display: none; } /* 스크롤 바 상 하단 버튼 */