h2 {
  font-weight: 500;
  font-size: 2.5rem;
}

h2 > p {
  font-size: 1.3rem;
  font-weight: normal;
  margin-top: 60px;
  word-break: keep-all;
  width: 50%;
}

#lnb {
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #aaa;
  display: flex;
  justify-content: center;
}

#lnb a {
  display: inline-block;
  width: 100%;
  height: calc(100% - 40px);
  line-height: 40px;
  font-size: 1.2rem;
  color: #aaa;
  border-right: 1px solid #aaa;
  margin: 20px 0px;
}

#lnb > div {
  position: relative;
  width: 15%;
  min-width: 200px;
}

#lnb > div:last-child a {
  border-right: none;
}

#lnb a.on {
  font-weight: bold;
  color: #333;
}

#lnb div.on {
  font-weight: bold;
  color: #333;
  width: 100%;
  height: 5px;
  background-color: #2364bc;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
}
