.footerContainer {
    width: 100%;
    height: 300px;
    background-color: #F9F9F9;
    color: #555555;
    display: flex;
    align-items: center;
}

.subfooterCon {
    width: 70%;
    max-width: 1200px;
    margin:auto;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.subfooterCon > p:first-child {
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: .1rem;
    margin-bottom: 20px;
}

.infoCon {
    display: flex;
    flex-wrap: wrap;
}

.infoCon p {
    margin-right: 60px;
    margin-bottom: 10px;
}

.infoCon p:nth-child(2) {
    width: 70%;
}

.infoCon span {
    color: #BAC0CF;
    margin-right: 10px;
}

.subfooterCon > img {
    width: 180px;
    margin-top: 50px;
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width:480px) and (max-width:767px) {
    .footerContainer {
        height: 400px;
    }
    .subfooterCon {
        width: 80%;
    }
    .infoCon p:nth-child(2) {
        width: auto;
    }
    .subfooterCon > img {
        width: 130px;
    }
}

/* 모바일  세로 (해상도 ~ 479px)*/
@media all and (max-width:479px) {
    .footerContainer {
        height: 400px;
    }
    .subfooterCon {
        width: 80%;
    }
    .infoCon p:nth-child(2) {
        width: auto;
    }
    .subfooterCon > img {
        width: 120px;
        margin-top: 40px;;
    }
    .subfooterCon > p:first-child {
        font-size: .9rem;

    }.infoCon > p {
        font-size: .75rem;
    }
}