/* ---------------- fullpage ---------------- */

.section {
    height: 100vh;
}

.section.first h2 {
    color: #fff;
    font-size: 4rem;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 600;
    text-align: center;
    line-height: 5.5rem;
    animation: spacing infinite 5s;
}

@keyframes spacing {
    0% {letter-spacing: 1.7rem;}
    50% {letter-spacing: 2rem;}
    100% {letter-spacing: 1.7rem;}
}

.section.first {
    position: relative;
    overflow: hidden;
    background: #eef6ff;
}

.section.second > div {
    background: url('../images/mainBg2.png') no-repeat top / cover;
}

.section.third > div {
    background: url('../images/mainBg3.jpg') no-repeat center / cover;
}

.section.forth > div {
    background-color: #F1F7FF;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
    background-color: rgba(58, 87, 255, 0.4) !important;
}

/* ---------------- slides ---------------- */

.slide-container, .slide-container div {
    height: 100%;
}

.each-fade > div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.react-slideshow-container .default-nav {
    display: none !important;
}

.section h2 {
    font-size: 5rem;
    letter-spacing: 0.5rem;
    text-align: center;
    position: relative;
    top: 180px;
}

.section h2 > span {
    font-size: 1.5rem;
    letter-spacing: 2rem;
    display: block;
    font-weight: normal;
}


/* ---------------- section 1 ---------------- */
.mainBg {
    position: absolute;
    width: 80% !important;
    height: 90% !important;
    border-radius: 100px;
top: -40%;
    right: -4%;
    transform: rotate(30deg);
    background-color: #2364BC;
}

.mainObj {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

.Dobj1 {
    position: absolute;
    z-index: 9;
    top: 15%;
    right: 30%;
    animation: Dobj1 1.5s ease-in-out infinite;
}

.Dobj2 {
    position: absolute;
    z-index: 9;
    top: 30%;
    right: 5%;
    animation: Dobj2 3s ease-in-out infinite;
}

.Dobj3 {
    position: absolute;
    z-index: 9;
    top: 55%;
    right: 22.6%;
    animation: Dobj3 2s ease-in-out infinite;
}

@keyframes Dobj1 {
    0% {top: 15%;}
    50% {top: 14%;}
    100% {top: 15%;}
}

@keyframes Dobj2 {
    0% {top: 30%;}
    50% {top: 32%;}
    100% {top: 30%;}
}

@keyframes Dobj3 {
    0% {top: 55%; right: 22.6%;}
    50% {top: 56%; right: 22%;}
    100% {top: 55%; right: 22.6%;}
}

/* ---------------- section 2 ---------------- */
.section > div > div {
    width: 100%;
    height: 100%;
    /* position: relative; */
}

.second h2 {
    color: #fff;
}

.second .contents {
    position: absolute;
    bottom: 0;
    display: flex;
    color: #fff;
    width: 100%;
    height: 60%;
    justify-content: center;
    text-align: left;
}

.second .contents > div {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 50px 50px 80px;
    /* border: 1px solid red; */
}

.second .contents > div > * {
    margin: 10px 0px;
}

.second .contents > div > p:first-child {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.5rem;
}

.bar {
    width: 0;
    height: 2px;
    background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    transition: width .4s;
}

.second .contents > div > p:nth-child(3) {
    font-size: 1.4rem;
    line-height: 2.5rem;
}

.second .contents > div > p:nth-child(3), .second .contents > div > a:nth-child(4) {
    color: #fff;
    opacity: 0;
    height: 0;
    transition: all .2s;
}

.second .contents > div:hover {
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}

.second .contents > div:hover > p:first-child {
    color: #AACD06;
}

.second .contents > div:hover .bar {
    width: 100%;
}

.second .contents > div:hover > p:nth-child(3), .second .contents > div:hover > a:nth-child(4) {
    opacity: 1;
    pointer-events: auto;
    height: unset;
}

.second .contents > div:hover > p:nth-child(3) {
    margin: 40px 0px;
}

.second .contents > div:hover > a:nth-child(4) > img {
    display: inline-block;
    height: 40%;
    margin-left: 10px;
}

.second .contents > div:hover > a:nth-child(4) > img {
    animation: arrow .5s infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
}

@keyframes arrow {
    from {margin-left: 10px;}
    to {margin-left: 20px;}
}

.vision, .value {
    border-right: 2px solid rgba(255, 255, 255, 0.3);
}


/* ---------------- section 3 ---------------- */
.third .contents {
    width: 100%;
    position: relative;
    top: 24%;
    display: flex;
    justify-content: center;
}

.third .contents > .webDiv {
    width: 15%;
}

.third .contents > .webDiv > div:first-child {
    /* border: 1px solid red; */
    box-shadow: 1px 1px 10px 0px rgba(0,0,0, 0.3);
    width: 90%;
    min-height: 550px;
    margin: 0px auto 10px;
    padding: 40px;
    position: relative;
    top: 20px;
    transition: top .3s;
    overflow: hidden;
    cursor: pointer;
}

.third .contents > .webDiv > div:first-child > p {
    font-size: 1.5rem;
    font-weight: bold;
}

.third .contents > .webDiv > div:first-child > p > span {
    display: block;
    font-size: 1rem;
    font-weight: normal;
    margin-top: 10px;
}

.third .contents > .webDiv > div:first-child > a {
    position: absolute;
    bottom: 40px;
    left: -100px;
    transition: left .3s;
}

.third .contents > .webDiv > div:first-child > a > img {
    width: 90px;
}

.third .contents > .webDiv {
    text-align: left;
}

.third .contents > .webDiv > div:last-child {
    width: 0%;
    overflow: hidden;
    float: right;
    transition: width .5s;
}

.third .contents > .webDiv > div:last-child > p {
    margin: 0 auto;
    min-width: 270px;
    font-size: 1.1rem;
    border-left: 10px solid #2364BC;
    padding-left: 15px;
    width: 90%;
    opacity: 0;
}

.third .contents > .webDiv:hover > div:first-child {
    top: -30px;
}

.third .contents > .webDiv:hover > div:first-child > a {
    left: 40px;
}

.third .contents > .webDiv:hover > div:last-child {
    width: 100%;
}

.third .contents > .webDiv:hover > div:last-child > p {
    opacity: 1;
}

.third .contents > .webDiv > div:first-child > p {
    color: #a9a9a9;
}

.third .contents > .webDiv > div:hover > p {
    color: #fff;
}

.digitaltwin > div:first-child {
    background: url('../images/main-digitaltwin-grey.png') no-repeat center / cover;
    transition: all .2s;
}
.digitaltwin:hover > div:first-child {
    background: url('../images/main-digitaltwin.png') no-repeat center / cover;
    color: #fff;
}

.deeplearning > div:first-child {
    background: url('../images/main-deeplearning-grey.png') no-repeat center / cover;
    transition: all .2s;
}
.deeplearning:hover > div:first-child {
    background: url('../images/main-deeplearning.png') no-repeat center / cover;
    color: #fff;
}

.barrot > div:first-child {
    background: url('../images/main-barrot-grey.png') no-repeat center / cover;
    transition: all .2s;
}
.barrot:hover > div:first-child {
    background: url('../images/main-barrot.png') no-repeat center / cover;
    color: #fff;
}

.port > div:first-child {
    background: url('../images/main-port-grey.png') no-repeat center / cover;
    transition: all .2s;
}
.port:hover > div:first-child {
    background: url('../images/main-port.png') no-repeat center / cover;
    color: #fff;
}

.workflow > div:first-child {
    background: url('../images/main-workflow-grey.png') no-repeat center / cover;
    transition: all .2s;
}
.workflow:hover > div:first-child {
    background: url('../images/main-workflow.png') no-repeat center / cover;
    color: #fff;
}

.monitoring > div:first-child {
    background: url('../images/main-monitoring-grey.png') no-repeat center / cover;
    transition: all .2s;
}
.monitoring:hover > div:first-child {
    background: url('../images/main-monitoring.png') no-repeat center / cover;
    color: #fff;
}

/* ------------------------------------- */

.third .mContents {
    width: 100%;
    position: relative;
    top: 30%;
}

.mItems {
    margin-bottom: 50px;
    height: 300px;
    text-align: left;
    padding: 40px ;
    color: #fff;
    cursor: pointer;
}

.mItems > p {
    font-size: 1.5rem;
}

.mItems > p > span {
    display: block;
    font-size: 1rem;
}

.mDigitaltwin {
    background: url('../images/main-M-digitaltwin.png') no-repeat center / cover;
}

.mDeeplearning {
    background: url('../images/main-M-deep.png') no-repeat center / cover;
}

.mBarrot {
    background: url('../images/main-M-barrot.png') no-repeat center / cover;
}

.mPort {
    background: url('../images/main-M-port.png') no-repeat center / cover;
}

.mWorkflow {
    background: url('../images/main-M-cloud.png') no-repeat center / cover;
}

.mMonitoring {
    background: url('../images/main-M-monitoring.png') no-repeat center / cover;
}


/* ---------------- section 4 ---------------- */
.forth .contents {
    width: 100%;
    height: 55%;
    display: flex;
    justify-content: center;
    position: relative;
    top: 20%;
}

.forth .contents > div {
    width: 20%;
    height: 100%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border-left: 1px solid #F1F7FF;
    border-right: 1px solid #F1F7FF;
}

.forth .contents > div > .bg {
    width: 100%;
    height: 100%;
    transition: all .3s;
}

.forth .contents > .m2m > .bg {
    background: url('../images/m2m.png') no-repeat center / cover;
    /* border-left: 2px solid #F1F7FF; */
}

.forth .contents > .installEx > .bg {
    background: url('../images/example.jpg') no-repeat center / cover;
    /* border-left: 2px solid #F1F7FF; */
}

.forth .contents > .product > .bg {
    background: url('../images/product.png') no-repeat center / cover;
    /* border-left: 2px solid #F1F7FF; */
}

.forth .contents > div:hover > .bg {
    transform: scale(1.05);
}

.forth .contents > div > .linkBox {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    font-size: 2.5rem;
    font-weight: bold;
    padding: 45% 0 0 10%;
    text-align: left;
    background-color: rgba(0,0,0, 0.3);
    opacity: 0;
    transition: opacity .5s;
}

.forth .contents > div > .linkBox > span {
    font-size: 1rem;
    font-weight: normal;
    display: inline-block;
    margin-top: 100px;
}

.forth .contents > div > .linkBox > span > img {
    width: 50px;
    margin-left: 10px;
    animation: arrow .5s infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
}

.forth .contents > div:hover > .linkBox {
    opacity: 1;
} 


/* ---------------- section 5 ---------------- */
.fifth .contents {
    display: flex;
}

.fifth .contents > div {
    width: 50%;
    position: relative;
}

.fifth .contents > .footer-left {
    text-align: left;
    padding: 100px 100px 100px 13%;
    background-color: #031D3C;
    color: #fff;
}

.fifth .contents > .footer-right {
    padding: 100px 150px;
}

.fifth .contents > div > h2 {
    text-align: left;
    z-index: 1;
    margin: 20px 0px;
    top: 130px;
}

.fifth .contents > .footer-left .circle1 {
    width: 100px;
    height: 100px;
    background-color: #AACD06;
    border-radius: 50%;
    position: absolute;
    left: 285px;
    top: 230px;
    z-index: 0;
    animation: circleUp 1s infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
}

/* .fifth .contents > .footer-left .circle2 {
    width: 75px;
    height: 75px;
    border: 3px solid #2364BC;
    border-radius: 50%;
    position: absolute;
    top: 300px;
    left: 700px;
    animation: circleScale .8s infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
} */

.fifth .contents > .footer-right .circle1 {
    width: 100px;
    height: 100px;
    border: 3px solid #AACD06;
    border-radius: 50%;
    position: absolute;
    top: 290px;
    left: 550px;
    animation: circleScale .8s infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
}

@keyframes circleUp {
    from {transform: translateY(0);}
    to {transform: translateY(10px);}
}

@keyframes circleScale {
    from {transform: scale(1);}
    to {transform: scale(1.1);}
}

.fifth .contents > .footer-left > .footerCon {
    position: relative;
    top: 20%;
    height: 40%;
}

.footerCon h3 {
    font-size: 2rem;
    margin: 20px 0px;
}

.footerCon > div {
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: space-evenly;
    font-size: 1.2rem;
}

.footerCon > div p {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.footerCon > div p > span {
    font-weight: normal;
    display: inline-block;
    width: 80%;
}

.footerCon > p:last-child {
    color: #ccc;
    font-size: .9rem;
    margin-top: 100px;
}

.fifth .contents > .footer-right {
    background-color: #eee;
}

#myMap {
    width: 100%;
    height: 550px;
    margin: 0 auto;
    position: relative;
    top: 20%;
}
 


/* --------------------------------------------------------------------------------------------- */
@media all and (min-width: 1920px) and (max-width: 2449px) {
    .second .contents > div {
        padding: 40px 40px 80px;
    }

    .second .contents > div > p:first-child {
        font-size: 1.8rem;
    }

    .second .contents > div > p:nth-child(3) {
        font-size: 1.2rem;
        line-height: 2rem;
    }

    .third .contents {
        top: 25%;
    }

    .third .contents > div > div:first-child {
        min-height: 450px !important;
    }

    .fifth .contents > .footer-left {
        padding: 80px 80px 80px 10%;
    }

    .fifth .contents > .footer-right {
        padding: 80px 140px;
    }

    .fifth .contents > div > h2 {
        top: 120px;
        font-size: 3.5rem;
    }

    #myMap {
        height: 430px;
        top: 25%;
    }

    .fifth .contents > .footer-left .circle1 {
        left: 130px;
        top: 170px;
    }

    .fifth .contents > .footer-right .circle1 {
        top: 220px;
        left: 445px;
    }

    .fifth .contents > .footer-left > .footerCon {
        top: 25%;
    }

    .footerCon > h3 {
        font-size: 1.5rem;
    }

    .footerCon > img {
        width: 300px;
    }

    .footerCon > div {
        font-size: 1rem;
    }

    .footerCon > p:last-child {
        margin-top: 70px;
    }
}

/* PC 2 */
@media all and (min-width: 1201px) and (max-width: 1919px) {
    .second h2 {
        font-size: 4rem;
    }

    .section h2 {
        font-size: 4rem;
        top: 140px;
    }

    .section h2 > span {
        font-size: 1.3rem;
    }

    .second .contents {
        height: 55%;
    }

    .second .contents > div {
        width: 30%;
        padding: 40px 40px 60px;
    }

    .second .contents > div > p:first-child {
        font-size: 1.5rem;
    }

    .second .contents > div:hover > p:nth-child(3) {
        margin: 25px 0px;
        font-size: 1.1rem;
        line-height: 1.9rem;
    }

    .third .contents {
        flex-wrap: wrap;
        top: 22%;
    }

    .third .contents > div {
        width: 30%;
    }

    .third .contents > div > div:first-child {
        min-height: 200px !important;
        padding: 30px;
    }

    .third .contents > div > div:first-child > p {
        font-size: 1.2rem;
    }

    .third .contents > div > div:first-child > a > img {
        width: 60px;
    }

    .fifth .contents > .footer-left {
        padding: 80px 40px 40px 9%;
    }

    .fifth .contents > .footer-left .circle1 {
        width: 80px;
        height: 80px;
        top: 200px;
        left: 80px;
    }

    .fifth .contents > .footer-right {
        padding: 80px 100px;
    }

    .fifth .contents > .footer-right .circle1 {
        
        width: 80px;
        height: 80px;
        top: 240px;
        left: 450px;
    }

    .fifth .contents > .footer-left > .footerCon {
        top: 22%;
    }

    .footerCon h3 {
        font-size: 1.5rem;
        margin: 0;
    }

    .footerCon > img {
        width: 300px;
    }

    .footerCon > div {
        font-size: 1rem;
    }

    #myMap {
        height: 410px;
    }
}

/* PC , 테블릿 가로 (해상도 1024px ~ 1200px)*/
@media all and (min-width:1024px) and (max-width: 1200px) {
    .section h2 {
        font-size: 3rem;
        top: 140px;
    }

    .section h2 > span {
        font-size: 1.1rem;
        letter-spacing: 1.5rem;
    }

    .second .contents {
        height: 45%;
    }

    .second .contents > div {
        width: 30%;
        padding: 30px 30px 40px;
    }

    .second .contents > div > * {
        margin: 5px 0px;
    }

    .second .contents > div > p:first-child {
        font-size: 1.5rem;
        letter-spacing: .3rem;
    }

    .second .contents > div:hover > p:nth-child(3) {
        margin: 20px 0px;
        font-size: 1.1rem;
        line-height: 1.9rem;
    }

    /* .third .contents {
        flex-wrap: wrap;
        top: 22%;
    }

    .third .contents > div {
        width: 30%;
    }

    .third .contents > div > div:first-child {
        min-height: 200px;
        padding: 30px;
    }

    .third .contents > div > div:first-child > p {
        font-size: 1.2rem;
    }

    .third .contents > div > div:first-child > a > img {
        width: 60px;
    } */

    .fifth .contents > div > h2 {
        top: 80px;
    }

    .fifth .contents > .footer-left {
        padding: 80px 40px 40px 9%;
    }

    .fifth .contents > .footer-left .circle1 {
        width: 80px;
        height: 80px;
        top: 140px;
        left: 60px;
    }

    .fifth .contents > .footer-right {
        padding: 80px 100px;
    }

    .fifth .contents > .footer-right .circle1 {
        
        width: 80px;
        height: 80px;
        top: 190px;
        left: 380px;
    }

    .fifth .contents > .footer-left > .footerCon {
        top: 22%;
    }

    .footerCon h3 {
        font-size: 1.5rem;
        margin: 0;
    }

    .footerCon > img {
        width: 300px;
    }

    .footerCon > div {
        font-size: 1rem;
    }

    #myMap {
        height: 350px;
    }
}

/* 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (min-width:768px) and (max-width:1023px) {
    .section h2 {
        font-size: 3rem;
        top: 140px;
    }

    .section h2 > span {
        font-size: 1.1rem;
        letter-spacing: 1.5rem;
    }

    .second .contents {
        height: 45%;
    }

    .second .contents > div {
        width: 33.3%;
        padding: 30px 20px 40px;
    }

    .second .contents > div > * {
        margin: 5px 0px;
    }

    .second .contents > div > p:first-child {
        font-size: 1.2rem;
        letter-spacing: .3rem;
    }

    .second .contents > div:hover > p:nth-child(3) {
        margin: 20px 0px;
        font-size: 1.1rem;
        line-height: 1.9rem;
    }

    .fifth .contents {
        flex-direction: column;
    }

    .fifth .contents > div {
        width: 100%;
        height: 100%;
    }

    .fifth .contents > div > h2 {
        top: 110px;
    }

    .fifth .contents > .footer-left {
        padding: 100px;
    }

    .fifth .contents > .footer-left > .footerCon {
        top: 23%;
        height: auto;
    }

    .footerCon h3 {
        font-size: 1.3rem;
    }

    .footerCon > img {
        width: 300px;
    }

    .footerCon > div {
        font-size: 1rem;
    }

    .footerCon > div p {
        line-height: 1.5rem;
    }

    .footerCon > p:last-child {
        margin: 40px 0px 20px;
    }

    .fifth .contents > .footer-left .circle1 {
        width: 70px;
        height: 70px;
        left: 70px;
        top: 190px;
    }

    .fifth .contents > .footer-right {
        display: none;
    }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/ 
@media all and (min-width:480px) and (max-width:767px) {
    .section h2 {
        font-size: 2rem;
        top: 140px;
    }

    .section h2 > span {
        font-size: 1rem;
        letter-spacing: .8rem;
    }

    .second .contents {
        height: 65%;
        flex-direction: column;
        justify-content: flex-end;
    }

    .second .contents > div {
        width: 100%;
        padding: 20px 20px 20px;
    }

    .second .contents > div > * {
        margin: 5px 0px;
    }

    .second .contents > div > p:first-child {
        font-size: 1.2rem; 
        letter-spacing: .3rem;
    }

    .second .contents > div:hover > p:nth-child(3) {
        margin: 20px 0px;
        font-size: 1.1rem;
        line-height: 1.9rem;
    }

    .vision, .value {
        border-right: none;
        border-bottom: 2px solid rgba(255, 255, 255, 0.3);
    }

    .fifth .contents {
        flex-direction: column;
    }

    .fifth .contents > div {
        width: 100%;
        height: 100%;
    }

    .fifth .contents > div > h2 {
        top: 130px;
    }

    .fifth .contents > .footer-left {
        padding: 60px;
    }

    .fifth .contents > .footer-left > .footerCon {
        top: 24%;
        height: auto;
    }

    .footerCon h3 {
        font-size: 1.3rem;
    }

    .footerCon > img {
        width: 300px;
    }

    .footerCon > div {
        font-size: 1rem;
    }

    .footerCon > div p {
        line-height: 1.8rem;
    }

    .footerCon > p:last-child {
        margin: 40px 0px 20px;
    }

    .fifth .contents > .footer-left .circle1 {
        width: 70px;
        height: 70px;
        left: 35px;
        top: 170px;
    }

    .footerCon > div p > span {
        width: 75%;
    }

    .fifth .contents > .footer-right {
        display: none;
    }
}

/* 모바일  세로 (해상도 ~ 479px)*/
@media all and (max-width:479px) {
    .section h2 {
        font-size: 2rem;
        top: 140px;
    }

    .section h2 > span {
        font-size: 1rem;
        letter-spacing: .8rem;
    }

    .second .contents {
        height: 65%;
        flex-direction: column;
        justify-content: flex-end;
    }

    .second .contents > div {
        width: 100%;
        padding: 20px 0px 0px 25px;
    }

    .second .contents > div > * {
        margin: 5px 0px;
    }

    .second .contents > div:hover .bar {
        width: 90%;
    }

    .second .contents > div > p:first-child {
        font-size: 1.2rem; 
        letter-spacing: .3rem;
    }

    .second .contents > div:hover > p:nth-child(3) {
        font-size: 1rem;
        line-height: 1.7rem;
    }
    
    .second .contents > div:hover > p:nth-child(3) {
        margin: 10px 0px;
    }

    .second .contents > div > a:nth-child(4) {
        font-size: .8rem;
    }

    .second .contents > div:hover > a:nth-child(4) > img {
        height: 30%;
    }

    .vision, .value {
        border-right: none;
        border-bottom: 2px solid rgba(255, 255, 255, 0.3);
    }

    .mItems {
        background-position: 65%;
    }

    .fifth .contents {
        flex-direction: column;
    }

    .fifth .contents > div {
        width: 100%;
        height: 100%;
    }

    .fifth .contents > div > h2 {
        top: 100px;
    }

    .fifth .contents > .footer-left {
        padding: 60px 45px;
    }

    .fifth .contents > .footer-left > .footerCon {
        top: 20%;
        height: auto;
    }

    .footerCon h3 {
        font-size: 1.1rem;
    }

    .footerCon > img {
        width: 200px;
    }

    .footerCon > div {
        font-size: .8rem;
    }

    .footerCon > div p {
        line-height: 1.8rem;
    }

    .footerCon > p:last-child {
        margin: 40px 0px 20px;
        font-size: .7rem;
    }

    .fifth .contents > .footer-left .circle1 {
        width: 60px;
        height: 60px;
        left: 15px;
        top: 150px;
    }

    .footerCon > div p > span {
        width: 70%;
    }

    .fifth .contents > .footer-right {
        display: none;
    }
}