.mockupDiv {
  background: url("../images/Mockup.png") no-repeat center / cover;
  width: 1200px;
  height: 970px;
  position: relative;
}

.mockupImg {
  position: absolute;
  top: 48px;
  left: 50px;
  max-height: 615px;
  overflow: hidden;
}

.mockupImg img {
  width: 1100px;
}

.active {
  visibility: visible;
}

.deactive {
  visibility: hidden;
}


/* PC 2 */
@media all and (min-width: 1201px) and (max-width: 1919px) {
  .mockupDiv {
    width: 1000px;
    height: 809px;
  }

  .mockupImg {
    top: 41px;
    left: 44px;
    max-height: 510px;
  }

  .mockupImg img {
    width: 913px;
  }
}

/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (min-width:1024px) and (max-width: 1200px) {
  .mockupDiv {
    width: 900px;
    height: 728px;
  }

  .mockupImg {
    top: 37px;
    left: 39px;
    max-height: 460px;
  }

  .mockupImg img {
    width: 822px;
  }
}

/* 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (min-width:768px) and (max-width:1023px) {
  .mockupDiv {
    width: 720px;
    height: 582px;
  }

  .mockupImg {
    top: 29px;
    left: 30px;
    max-height: 370px;
  }

  .mockupImg img {
    width: 660px;
  }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width:480px) and (max-width:767px) {
  .mockupDiv {
    width: 450px;
    height: 364px;
  }

  .mockupImg {
    top: 18px;
    left: 20px;
    max-height: 230px;
  }

  .mockupImg img {
    width: 411px;
  }
}

/* 모바일  세로 (해상도 ~ 479px)*/
@media all and (max-width:479px) {
  .mockupDiv {
    width: 350px;
    height: 283px;
  }

  .mockupImg {
    top: 14px;
    left: 15px;
    max-height: 178px;
  }

  .mockupImg img {
    width: 320px;
  }
}