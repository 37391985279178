.barrotContainer {
    overflow: hidden;
}

.barrotHeader {
    width: 100%;
    height: 520px;
    color: #fff;
    text-shadow: rgba(0,0,0,0.3) 1px 1px 5px;
    background: url('../images/header-barrot.png') no-repeat center / cover;
    display: flex;
    align-items: center;
}

.barrotHeader > * {
    width: 1200px;
    margin: 0 auto;
    text-align: left;
}

.barrotCon {
    width: 1200px;
    margin: 0 auto;
    position: relative;
}

.barrotCon > div {
    margin: 200px 0px;
}

.barrotInfoTop > img {
    width: 1000px;
    display: inline-block;
    margin: 0 auto;
}

.barrotInfoBottom > h3 {
    font-size: 2rem;
}

.barrotInfoBottom > .line {
    width: 1px;
    height: 150px;
    background-color: #3483ED;
    margin: 60px auto;
}

.barrotInfoBottom > .infoText {
    font-size: 1.4rem;
}

.barrotInfoBottom > .infoText > span {
    font-weight: 600;
}

.barrotIcoSec {
    display: flex;
    justify-content: space-between;
}

.barrotIcoSec > div {
    width: 580px;
    box-shadow: 0px 2px 25px rgba(52, 131, 237, 0.15);
    border-radius: 20px;
    padding: 80px 40px;
}

.barrotIcoSec > div > img {
    display: inline-block;
    margin-bottom: 80px;
}

.barrotIcoSec > div > p {
    font-size: 1.5rem;
    font-weight: 600;
}

.barrotIcoSec > div > p > span {
    display: inline-block;
    font-size: 1.3rem;
    font-weight: normal;
    line-height: 1.6rem;
    margin-top: 20px;
    word-break: keep-all;
}

.function > h3 {
    width: 100%;
    text-align: left;
    color: #3483ED;
    font-weight: normal;
    margin-bottom: 40px;
    font-size: 1.3rem;
}

.function > div {
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    height: 350px;
    padding: 0px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.function > div:nth-child(even) > div:first-child {
    width: 30%;
    word-break: keep-all;
    text-align: left;
    font-size: 1.3rem;
}

.function > div:nth-child(odd) > div:last-child {
    width: 30%;
    word-break: keep-all;
    text-align: right;
    font-size: 1.3rem;
}

.function > div > div > p {
    font-size: 1.5rem;
    font-weight: 600;
}

.function > div > div > p:first-child {
    color: #3483ED;
    margin-bottom: 5px;
}

.function > div > div > span {
    display: inline-block;
    margin-top: 20px;
}

/* PC 2 */
@media all and (min-width: 1201px) and (max-width: 1919px) {
    #headerWrap {
        width: 1000px;
    }
    .barrotHeader {
        background-position: 100% 0px ;
        height: 450px;
    }
    .header > * {
        width: 1000px ;
    }
    .header > h2 {
        font-size: 2.3rem ;
    }
    .header > h2 > p {
        font-size: 1.2rem ;
        margin-top: 40px ;
    } 

    #lnb a {
        font-size: .9rem ;
    }

    .Contents {
        width: 1000px ;
    }

    .barrotCon > div {
        margin: 150px 0px;
    }

    .barrotIcoSec > div {
        width: 480px;
        padding: 60px 40px;
    }

    .barrotIcoSec > div > p {
        font-size: 1.4rem;
    }

    .barrotIcoSec > div > p > span {
        font-size: 1.2rem;
    }

    .barrotIcoSec > div:first-child > img {
        height: 130px;
    }

    .barrotIcoSec > div:last-child > img {
        width: 130px;
    }

    .function > div {
        padding: 0px 60px;
    }

    .ReactModal__Content > img {
        width: 1000px ;
    }
}

/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (min-width:1024px) and (max-width: 1200px) {
    header {
        padding: 17px 12px ;
    }
    #headerWrap {
        width: 1000px;
    }
    .barrotHeader {
        background-position: 100% 0px ;
        height: 450px;
    }
    .header > * {
        width: 900px ;
    }
    h2 {
        font-size: 2.3rem ;
    }
    h2 > p {
        font-size: 1.2rem ;
        margin-top: 40px ;
    } 

    #lnb {
        display: none ;
    }

    .Contents {
        width: 900px ;
    }
    
    .barrotCon > div {
        margin: 150px 0px;
    }

    .Contents img {
        max-width: 900px ;
    }

    .barrotInfoBottom > h3 {
        font-size: 1.8rem;
    }
    
    .barrotInfoBottom > .line {
        height: 120px;
        margin: 50px auto;
    }

    .barrotInfoBottom > .infoText {
        font-size: 1.3rem;
    }

    .barrotIcoSec > div {
        width: 430px;
        padding: 60px 40px;
    }

    .barrotIcoSec > div > p {
        font-size: 1.3rem;
    }

    .barrotIcoSec > div > p > span {
        font-size: 1.1rem;
    }
    
    .barrotIcoSec > div:first-child > img {
        height: 130px;
    }

    .barrotIcoSec > div:last-child > img {
        width: 130px;
    }

    .function > div {
        padding: 0px 60px;
    }

    .function > div > div > p {
        font-size: 1.3rem;
    }

    .function > div > div > span {
        font-size: 1.2rem;
    }

    .funCon1 > div > img {
        width: 450px;
    }

    .funCon2 > div > img {
        width: 260px;
    }

    .funCon3 > div > img {
        width: 260px;
    }

    .function > div:nth-child(odd) > div:last-child {
        width: 38%;
    }

    .ReactModal__Content > img {
        width: 950px ;
    }
}

/* 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (min-width:768px) and (max-width:1023px) {
    header {
        padding: 17px 12px ;
    }
    #headerWrap {
        width: 95%;
    }
    .header {
        background-position: 80% 0px ;
        height: 380px ;
    }
    .header > h2 {
        font-size: 2rem ;
        width: 90%;
    }
    .header > h2 > p {
        font-size: 1.2rem ;
        margin-top: 20px ;
    } 

    #lnb {
        display: none ;
    }

    .Contents {
        width: 720px ;
    }

    .barrotCon > div {
        margin: 150px 0px;
    }

    .Contents img {
        max-width: 720px ;
    }

    .barrotInfoTop > img {
        width: 700px;
    }

    .barrotInfoBottom > h3 {
        font-size: 1.6rem;
    }
    
    .barrotInfoBottom > .line {
        height: 120px;
        margin: 50px auto;
    }

    .barrotInfoBottom > .infoText {
        font-size: 1.3rem;
    }

    .barrotIcoSec > div {
        width: 345px;
        padding: 50px 40px;
    }

    .barrotIcoSec > div > p {
        font-size: 1.3rem;
    }

    .barrotIcoSec > div > p > span {
        font-size: 1.1rem;
    }
    
    .barrotIcoSec > div:first-child > img {
        height: 100px;
        margin-bottom: 40px;
    }

    .barrotIcoSec > div:last-child > img {
        width: 100px;
        margin-bottom: 40px;
    }

    .function > h3 {
        margin-bottom: 20px;
    }

    .function > div {
        padding: 0px 60px;
    }

    .function > div > div > p {
        font-size: 1.3rem;
    }

    .function > div > div > span {
        font-size: 1.2rem;
    }

    .funCon1 > div > img {
        width: 400px;
    }

    .funCon2 > div > img {
        width: 230px;
    }

    .funCon3 > div > img {
        width: 230px;
    }

    .function > div:nth-child(even) > div:first-child {
        width: 35%;
    }

    .function > div:nth-child(odd) > div:last-child {
        width: 40%;
    }
    
    .ReactModal__Content > img {
        width: 750px;
    }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width:480px) and (max-width:767px) {
    header {
        padding: 17px 5px ;
    }
    h1 > a {
        width: 140px;
    }
    #headerWrap {
        width: 95%;
    }

    .barrotHeader {
        background: url(../images/M-traslation-solution.png) no-repeat bottom / cover;
        height: 600px;
        align-items: unset;
    }

    .header > h2 {
        font-size: 1.6rem ;
        width: 85% ;
        margin-top: 130px;
        font-weight: bold;
    }

    .header > h2 > p {
        font-size: .9rem ;
        margin-top: 30px ;
        width: 90%;
        line-height: 1.6rem;
    } 

    #lnb {
        display: none ;
    }

    .Contents {
        width: 420px ;
    }

    .barrotCon > div {
        margin: 100px 0px;
    }

    .Contents img {
        max-width: 420px ;
    }

    .barrotInfoTop > img {
        width: 700px;
    }

    .barrotInfoBottom > h3 {
        font-size: 1.4rem;
    }
    
    .barrotInfoBottom > .line {
        height: 120px;
        margin: 50px auto;
    }

    .barrotInfoBottom > .infoText {
        font-size: 1.1rem;
    }

    .barrotIcoSec {
        flex-direction: column;
    }

    .barrotIcoSec > div {
        width: 100%;
        padding: 40px 40px;
    }

    .barrotIcoSec > div:first-child {
        margin-bottom: 20px;
    }

    .barrotIcoSec > div > p {
        font-size: 1.3rem;
    }

    .barrotIcoSec > div > p > span {
        font-size: 1.1rem;
    }
    
    .barrotIcoSec > div:first-child > img {
        height: 100px;
        margin-bottom: 40px;
    }

    .barrotIcoSec > div:last-child > img {
        width: 100px;
        margin-bottom: 40px;
    }

    .function > h3 {
        margin-bottom: 20px;
    }

    .function > div {
        padding: 40px 5px;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
    }

    .function > div:nth-child(3) {
        flex-direction: column-reverse;
    }

    .function > div:nth-child(odd) > div:last-child {
        text-align: left;
    }

    .function > div > div > p {
        font-size: 1.3rem;
    }

    .function > div > div > span {
        font-size: 1.2rem;
    }

    .funCon1 > div > img {
        width: 400px;
    }

    .funCon2 > div > img {
        width: 230px;
    }

    .funCon3 > div > img {
        width: 230px;
    }

    .function > div:nth-child(even) > div:first-child {
        width: 100%;
        margin-bottom: 20px;
    }

    .function > div:nth-child(even) > div:last-child {
        margin: 0 auto;
    }

    .function > div:nth-child(odd) > div:last-child {
        width: 100%;
        margin-bottom: 20px;
    }

    .function > div:nth-child(odd) > div:first-child {
        margin: 0 auto;
    }

    .ReactModal__Content > img {
        width: 450px ;
    }
}

/* 모바일  세로 (해상도 ~ 479px)*/
@media all and (max-width:479px) {
    header {
        padding: 17px 5px ;
    }
    h1 > a {
        width: 140px;
    }
    #headerWrap {
        width: 95%;
    }

    .barrotHeader {
        background: url(../images/M-traslation-solution.png) no-repeat bottom / cover;
        height: 450px;
        align-items: unset;
    }

    .header > h2 {
        font-size: 1.6rem ;
        width: 85% ;
        margin-top: 130px;
        font-weight: bold;
    }

    .header > h2 > p {
        font-size: .9rem ;
        margin-top: 30px ;
        width: 90%;
        line-height: 1.6rem;
    } 

    #lnb {
        display: none ;
    }

    .Contents {
        width: 350px ;
    }

    .barrotCon > div {
        margin: 100px 0px;
    }

    .Contents img {
        max-width: 350px ;
    }

    .barrotInfoBottom > h3 {
        font-size: 1.2rem;
    }
    
    .barrotInfoBottom > .line {
        height: 100px;
        margin: 40px auto;
    }

    .barrotInfoBottom > .infoText {
        font-size: 1rem;
    }

    .barrotIcoSec {
        flex-direction: column;
    }

    .barrotIcoSec > div {
        width: 100%;
        padding: 25px 25px;
    }

    .barrotIcoSec > div:first-child {
        margin-bottom: 20px;
    }

    .barrotIcoSec > div > p {
        font-size: 1.2rem;
    }

    .barrotIcoSec > div > p > span {
        font-size: 1rem;
    }
    
    .barrotIcoSec > div:first-child > img {
        height: 80px;
        margin-bottom: 20px;
    }

    .barrotIcoSec > div:last-child > img {
        width: 80px;
        margin-bottom: 20px;
    }

    .function > h3 {
        font-size: 1rem;
        margin-bottom: 20px;
    }

    .function > div {
        padding: 40px 5px;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
    }

    .function > div:nth-child(3) {
        flex-direction: column-reverse;
    }

    .function > div:nth-child(odd) > div:last-child {
        text-align: left;
    }

    .function > div > div > p {
        font-size: 1.2rem;
    }

    .function > div > div > span {
        font-size: 1rem;
    }

    .funCon1 > div > img {
        width: 330px;
    }

    .funCon2 > div > img {
        width: 200px;
    }

    .funCon3 > div > img {
        width: 200px;
    }

    .function > div:nth-child(even) > div:first-child {
        width: 100%;
        margin-bottom: 20px;
    }

    .function > div:nth-child(even) > div:last-child {
        margin: 0 auto;
    }

    .function > div:nth-child(odd) > div:last-child {
        width: 100%;
        margin-bottom: 20px;
    }

    .function > div:nth-child(odd) > div:first-child {
        margin: 0 auto;
    }

    .ReactModal__Content > img {
        width: 350px ;
    }
}