.conContainer {
    position: relative;
}

.conTop {
    width: 100%;
    height: 400px;
    line-height: 400px;
    font-weight: bold;
    font-size: 2.5rem;
    color: #fff;
    text-shadow: rgba(0,0,0,0.3) 1px 1px 5px;
    background: url('../images/busTop.png') no-repeat center / cover;
}

.conContents h2 {
    margin: 0px auto 50px;
    font-size: 3rem;
    color: #2364BC;
}

.conContents > div {
    margin: 100px 0px;
    padding: 50px 0px;
}

.location > div {
    width: 50%;
    margin: 0 auto;
}

.location > div > div {
    text-align: left;
    margin: 20px 0px;
    height: 35px;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
}

.location > div > div p {
    line-height: 35px;
    margin-left: 10px;
}

form {
    display: flex;
    justify-content: space-evenly;
    width: 70%;
    flex-wrap: wrap;
    margin: 0 auto;
}

form > div:first-child, form > div:nth-child(2) {
    width: 30%;
    min-height: 50px;
    line-height: 50px;
}

form > div:nth-child(3) {
    width: 100%;
}

form input[type=text],form input[type=email] {
    padding: 5px;
    font-size: 1.2rem;
    border: unset;
    border-bottom: 2px solid #333;
    width: 100%;
    font-family: 'Noto Sans KR';
}

input:focus, textarea:focus {outline:none;}

form textarea {
    padding: 5px;
    font-size: 1.2rem;
    margin: 20px 0px;
    width: 74%;
    min-height: 150px;
    resize: none;
    border: unset;
    border-bottom: 2px solid #333;
    font-family: 'Noto Sans KR';
    color: #333;
}

form input[type=submit] {
    width: 150px;
    height: 50px;
    font-size: 1.5rem;
    border: none;
    font-weight: bold;
    background: url('../images/send.png');
    cursor: pointer;
    margin-top: 50px;
    color: #333;
    transition: .2s;
}

form input[type=submit]:hover {
    color: #2364BC;
    background: url('../images/sendhover.png');
}

.toastBox {
    width: 200px;
    height: 80px;
    background-color: rgba(0,0,0,0.3);
    border-radius: 10px;
    text-align: center;
    line-height: 80px;
    color: #fff;
    box-shadow: 0px 1px 10px rgba(0,0,0,0.1);
    position: fixed;
    top: calc(50% - 40px);
    left: calc(50% - 100px);
}

/* PC 2 */
@media all and (min-width: 1201px) and (max-width: 1919px) {
    .location > div {
        width: 60%;
    }

    form > div:first-child, form > div:nth-child(2) {
        width: 40%;
    }

    form textarea {
        width: 88%;
    }
}

/* PC , 테블릿 가로 (해상도 1024px ~ 1200px)*/
@media all and (min-width:1024px) and (max-width: 1200px) {
    .location > div {
        width: 70%;
    }

    form {
        width: 75%;
    }

    form > div:first-child, form > div:nth-child(2) {
        width: 40%;
    }

    form textarea {
        width: 88%;
    }
}

/* 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (min-width:768px) and (max-width:1023px) {
    .conTop {
        height: 380px;
        line-height: 380px;
    }

    .conContents > div {
        padding: 0;
    }

    .location > div {
        width: 75%;
    }
    
    #myMap {
        height: 400px;
    }

    form {
        width: 80%;
    }

    form > div:first-child, form > div:nth-child(2) {
        width: 40%;
    }

    form textarea {
        width: 88%;
    }

    form input[type=submit] {
        margin-top: 40px;
    }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/ 
@media all and (min-width:480px) and (max-width:767px) {
    .conTop {
        background: url(../images/M-contact.png) no-repeat bottom / cover;
        height: 400px;
        line-height: 400px;
        align-items: unset;
    }

    .conContents h2 {
        font-size: 2rem;
    }

    .conContents > div {
        padding: 0;
    }

    .location > div {
        width: 80%;
    }
    
    #myMap {
        height: 300px;
    }

    .location > div > div > img {
        width: 25px;
        height: 25px;
    }

    .location > div > div {
        font-size: .8rem;
        margin: 10px 0px;
    }

    form {
        width: 80%;
    }

    form > div:first-child, form > div:nth-child(2) {
        width: 40%;
    }

    form textarea {
        width: 88%;
    }

    form input[type=submit] {
        margin-top: 40px;
    }

    form input[type=text], form input[type=email], form textarea {
        font-size: .8rem;
    }

    form input[type=submit] {
        font-size: 1rem;
    }
}

/* 모바일  세로 (해상도 ~ 479px)*/
@media all and (max-width:479px) {
    .conTop {
        background: url(../images/M-contact.png) no-repeat bottom / cover;
        height: 400px;
        line-height: 400px;
        align-items: unset;
    }

    .conContents h2 {
        font-size: 2rem;
    }

    .conContents > div {
        padding: 0;
    }

    .location > div {
        width: 90%;
    }
    
    #myMap {
        height: 300px;
    }

    .location > div > div > img {
        width: 25px;
        height: 25px;
    }

    .location > div > div {
        font-size: .8rem;
        margin: 10px 0px;
    }

    form {
        width: 90%;
    }

    form > div:first-child, form > div:nth-child(2) {
        width: 40%;
    }

    form textarea {
        width: 88%;
    }

    form input[type=submit] {
        margin-top: 40px;
    }

    form input[type=text], form input[type=email], form textarea {
        font-size: .8rem;
    }

    form input[type=submit] {
        font-size: 1rem;
    }
}