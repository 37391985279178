.infoHeader {
  width: 100%;
  height: 520px;
  line-height: 520px;
  color: #fff;
  text-shadow: rgba(0,0,0,0.3) 1px 1px 5px;
  background: url('../images/header-info.png') no-repeat center / cover;
}

.InfoContainer > div:not(.infoHeader, #lnb, .footerContainer) {
  margin: 100px 0px;
  padding: 50px 0px;
}

.InfoContainer h2:not(.header > h2) {
    margin: 0px auto 50px;
    font-size: 3rem;
    color: #2364BC;
    letter-spacing: .3rem;
}

.InfoContainer > div > p {
  font-size: 1.5rem;
  line-height: 3rem;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

.valueIco {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: baseline;
}

.valueLine {
  width: 100px;
  height: 1px;
  background-color: #015eea;
}

.valueIco > div > div {
  width: 150px;
  height: 150px;
  line-height: 150px;
  margin: 0 auto 20px;
  background-color: #f1f7ff;
  border-radius: 50%;
}

.valueImg {
  width: 90px;
  vertical-align: middle;
  /* display: inline-block; */
}

.valueIco > div > p:nth-child(2) {
  font-size: 1.3rem;
  line-height: 1.1rem;
}

.valueIco > div > p:nth-child(3) {
  font-size: 2rem;
  color: #015eea;
} 

/* PC 2 */
@media all and (min-width: 1201px) and (max-width: 1919px) {
   .infoHeader {
    line-height: 450px;
    background-position: center !important;
  }

  .infoHeader > h2 {
    margin: 0 auto;
    height: 100%;
  }
}

/* PC , 테블릿 가로 (해상도 1024px ~ 1200px)*/
@media all and (min-width:1024px) and (max-width: 1200px) {
  .infoHeader {
    line-height: 450px;
    background-position: center !important;
  }
  
  .infoHeader > h2 {
    margin: 0 auto;
    height: 100%;
  }
}

/* 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (min-width:768px) and (max-width:1023px) {
  .infoHeader {
    line-height: 380px;
    background-position: center !important;
  }

  .infoHeader > h2 {
    margin: 0 auto;
    height: 100%;
  }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/ 
@media all and (min-width:480px) and (max-width:767px) {
  .infoHeader {
    background: url(../images/M-info.png) no-repeat bottom / cover;
    height: 400px;
    line-height: 400px;
    align-items: unset;
  }

  .InfoContainer > div:not(.infoHeader, #lnb, .footerContainer) {
    padding: 0;
  }

  .infoHeader > h2 {
    margin: 0 auto !important;
  }

  .InfoContainer h2:not(.header > h2) {
    font-size: 2rem
  }

  .InfoContainer > div > p {
    font-size: 1rem;
    line-height: 2rem;
  }
}

/* 모바일  세로 (해상도 ~ 479px)*/
@media all and (max-width:479px) {
  .infoHeader {
    background: url(../images/M-info.png) no-repeat bottom / cover;
    height: 400px;
    line-height: 400px;
    align-items: unset;
  }

  .InfoContainer > div:not(.infoHeader, #lnb, .footerContainer) {
    padding: 0;
  }

  .infoHeader > h2 {
    margin: 0 auto !important;
  }

  .InfoContainer h2:not(.header > h2) {
    font-size: 2rem
  }

  .InfoContainer > div > p {
    font-size: 1rem;
    line-height: 2rem;
  }
}