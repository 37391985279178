.deepContainer {
    overflow: hidden;
}

.deepHeader {
    width: 100%;
    height: 520px;
    color: #fff;
    text-shadow: rgba(0,0,0,0.3) 1px 1px 5px;
    background: url('../images/header-deeplearning.png') no-repeat center / cover;
    display: flex;
    align-items: center;
}

.deepHeader > * {
    width: 1200px;
    margin: 0 auto;
    text-align: left;
}

.deepCon {
    width: 1200px;
    margin: 0 auto;
    position: relative;
}

.deepCon > div {
    margin: 150px 0px
}

.pageInfo {
    font-size: 1.5rem;
    margin: 200px 0px;
}

.pageInfo > span {
    font-weight: bold;
}

.object, .action, .face {
    display: flex;
    margin: 200px 0px;
}

.tech > div > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
}

.tech > div:nth-child(odd) > div > * {
    width: calc(100% - 80px);
    margin-left: 80px;
}

.tech > div:nth-child(even) > div > * {
    width: calc(100% - 80px);
    margin-right: 80px;
}

.tech > div > div > p {
    font-size: 2rem;
    font-weight: 600;
}

.tech > div > div > span {
    display: inline-block;
    font-size: 1.5rem;
    white-space: normal;
    word-break: keep-all;
}

.deepUseCase {
    padding: 100px 0px;
    background-color: #F1F7FF;
    width: 100vw;
    margin: 150px 0px 150px calc(-50vw + 50%) !important;
}

.deepCaseWrap {
    width: 1200px;
    margin: 0 auto;
    text-align: left;
}

.deepCaseWrap > h3 {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: left;
}

.caseBoxContainer {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.caseBox {
    width: 100%;
    min-height: 100px;
    border-radius: 5px;
    margin-bottom: 40px;
    background-color: #fff;
    box-shadow: 1px 1px 15px rgba(52, 131, 237, 0.1);
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3rem;
}

.caseBox > div:first-child {
    width: 25%;
}

.caseBox:first-child > div:first-child, .caseBox:nth-child(2) > div:first-child {
    display: flex;
}

.caseBox > p {
    width: 55%;
}

.caseBox > p > span {
    font-size: 1.1rem;
}

.caseBox > button {
    width: 10%;
    height: 60px;
    border: none;
    font-size: 1rem;
    color: #3483ED;
    background-color: transparent;
    cursor: pointer;
}

.caseBox > button:hover {
    font-weight: bold;
}

.ModalCon {
    text-align: left;
    max-width: 1000px;
}

.ModalCon > img:first-child, .ModalCon > div:first-child > img  {
    width: auto;
    margin-bottom: 20px;
}

.ModalCon > div:first-child > img:first-child {
    margin-right: 20px;
}

.ModalCon > p {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 20px;
}

.ModalCon > p > span {
    font-size: 1.2rem;
}

.ModalCon > div > img {
    max-width: 900px;
    width: 100%;
}

.deeplearningProcess > h3 {
    color: #3483ED;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.deeplearningProcess > h3 > .line {
    width: 85%;
    height: 1px;
    background-color: #3483ED;
    margin-bottom: 5px;
}

.processWrap {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.processItem {
    width: 224px;
    height: 386px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.processItem > p {
    font-size: 2rem;
    margin: 67px 0px 80px 14px;
    font-weight: 600;
}

.processItem > div {
    padding: 0px 20px;
}

.processItem > div > p:first-child {
    font-size: 1.5rem;
    font-weight: 600;
}

.processItem > div > p:last-child {
    font-size: 1.2rem;
    word-break: keep-all;
    color: #333;
}

.processItem > div > .line {
    width: 30px;
    height: 2px;
    display: inline-block;
    margin: 15px 0px 20px;
}

.process1 {
    background: url(../images/deepProcessBg1.png) no-repeat right / cover;
    box-shadow: 2px 2px 5px rgba(6, 187, 255, 0.25);
    color: #06C2FD;
}

.process1 .line {
    background-color: #06C2FD;
}

.process2 {
    background: url(../images/deepProcessBg2.png) no-repeat right / cover;
    box-shadow: 2px 2px 5px rgba(73, 28, 255, 0.25);
    color: #5343FD;
}

.process2 .line {
    background-color: #5343FD;
}

.process3 {
    background: url(../images/deepProcessBg3.png) no-repeat right / cover;
    box-shadow: 2px 2px 5px rgba(176, 50, 255, 0.25);
    color: #9824EF;
}

.process3 .line {
    background-color: #9824EF;
}

.process4 {
    background: url(../images/deepProcessBg4.png) no-repeat right / cover;
    box-shadow: 2px 2px 5px rgba(255, 45, 121, 0.25);
    color: #FC41A5;
}

.process4 .line {
    background-color: #FC41A5;
}

.process5 {
    background: url(../images/deepProcessBg5.png) no-repeat right / cover;
    box-shadow: 2px 2px 5px rgba(255, 67, 48, 0.25);
    color: #FE405B;
}

.process5 .line {
    background-color: #FE405B;
}

/* ---------------------------------------------- */

.MdeeplearningProcess > h3 {
    color: #3483ED;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.MdeeplearningProcess > h3 > .line {
    width: 80%;
    height: 1px;
    background-color: #3483ED;
    margin-bottom: 5px;
}

.MprocessWrap {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.MprocessItem {
    width: 900px;
    height: 176.44px;
    border-radius: 20px;
    margin-bottom: 20px;
    display: flex;
}

.MprocessItem > p {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 50px 0px 0px 15px;
}

.MprocessItem > div {
    display: flex;
    margin: 50px 0px 0px 50px;
    position: relative;
}

.MprocessItem > div > p:first-child {
    font-size: 1.5rem;
    font-weight: 600;
}

.MprocessItem > div > .line {
    width: 5px;
    height: 26px;
    position: absolute;
    top: 2px;
    left: 130px;
}

.MprocessItem > div > p:last-child {
    color: #333;
    font-size: 1.3rem;
    margin-left: 50px;
}

.MprocessItem:last-child > div > p:last-child {
    margin-left: 75px;
}

.Mprocess1 {
    background: url(../images/M-deepProcessBg1.png) no-repeat center / cover;
    box-shadow: 2px 2px 5px rgba(6, 187, 255, 0.25);
    color: #06C2FD;
}

.Mprocess1 .line {
    background-color: #06C2FD;
}

.Mprocess2 {
    background: url(../images/M-deepProcessBg2.png) no-repeat center / cover;
    box-shadow: 2px 2px 5px rgba(73, 28, 255, 0.25);
    color: #5343FD;
}

.Mprocess2 .line {
    background-color: #5343FD;
}

.Mprocess3 {
    background: url(../images/M-deepProcessBg3.png) no-repeat center / cover;
    box-shadow: 2px 2px 5px rgba(176, 50, 255, 0.25);
    color: #9824EF;
}

.Mprocess3 .line {
    background-color: #9824EF;
}

.Mprocess4 {
    background: url(../images/M-deepProcessBg4.png) no-repeat center / cover;
    box-shadow: 2px 2px 5px rgba(255, 45, 121, 0.25);
    color: #FC41A5;
}

.Mprocess4 .line {
    background-color: #FC41A5;
}

.Mprocess5 {
    background: url(../images/M-deepProcessBg5.png) no-repeat center / cover;
    box-shadow: 2px 2px 5px rgba(255, 67, 48, 0.25);
    color: #FE405B;
}

.Mprocess5 .line {
    background-color: #FE405B;
}


/* PC 2 */
@media all and (min-width: 1201px) and (max-width: 1919px) {
    #headerWrap {
        width: 1000px;
    }
    .header {
        background-position: 80% 0px ;
        height: 450px ;
    }
    .header > * {
        width: 1000px ;
    }
    .header > h2 {
        font-size: 2.3rem ;
    }
    .header > h2 > p {
        font-size: 1.2rem ;
        margin-top: 40px ;
    } 

    #lnb a {
        font-size: .9rem ;
    }

    .Contents {
        width: 1000px ;
    }

    .pageInfo {
        font-size: 1.3rem ;
        margin: 150px 0px;
    }

    .object, .action, .face {
        margin: 150px 0px;
    }

    .tech > div > div > p {
        font-size: 1.8rem;
    }

    .tech > div > div > span {
        font-size: 1.3rem;
    }

    .deepCaseWrap {
        width: 1000px ;
    }

    .deepUseCase {
        padding: 80px 0px;
    }

    .caseBox > p {
        width: 50%;
    }

    .deeplearningProcess > h3 > .line {
        width: 82%;
    }

    .processItem  {
        width: 180px;
        height: 330px;
    }

    .processItem > p {
        margin: 53px 0px 50px 10px;
    }

    .processItem > div > p:last-child {
        font-size: 1.1rem;
    }

    .processItem > div {
        padding: 0px 15px;
    }

    .ReactModal__Content > img {
        width: 1000px ;
    }
}

/* PC , 테블릿 가로 (해상도 1024px ~ 1200px)*/
@media all and (min-width:1024px) and (max-width: 1200px) { 
    header {
        padding: 17px 12px ;
    }
    #headerWrap {
        width: 1000px;
    }
    .header {
        background-position: 80% 0px ;
        height: 450px ;
    }
    .header > * {
        width: 900px ;
    }
    h2 {
        font-size: 2.3rem ;
    }
    h2 > p {
        font-size: 1.2rem ;
        margin-top: 40px ;
    } 

    #lnb {
        display: none ;
    }

    .Contents {
        width: 900px ;
    }

    .Contents img {
        max-width: 900px ;
    }

    .pageInfo {
        font-size: 1.3rem ;
        margin: 150px 0px;
    }

    .object, .action, .face {
        margin: 150px 0px;
    }

    .tech > div > img {
        width: 400px;
    }

    .tech > div > div > p {
        font-size: 1.8rem;
    }

    .tech > div > div > span {
        font-size: 1.3rem;
    }

    .deepCaseWrap {
        width: 900px ;
    }

    .deepUseCase {
        padding: 80px 0px;
    }

    .caseBoxContainer {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .caseBox {
        padding: 20px;
        width: calc(50% - 20px);
        flex-direction: column;
        align-items: flex-start;
    }

    .caseBox > p {
        width: 100%;
    }

    .caseBox > div:first-child {
        width: 40%;
        margin-bottom: 20px;
    }

    .caseBox > div:first-child > img {
        width: 100%;
    }

    .caseBox > button {
        width: 20%;
        height: 20px;
        margin-top: 20px;
    }

    .ModalCon {
        max-width: 900px;
    }

    .ModalCon > img:first-child, .ModalCon > div:first-child > img {
        width: 22%;
    } 

    .ReactModal__Content > img {
        width: 950px;
    }
}

/* 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (min-width:768px) and (max-width:1023px) { 
    header {
        padding: 17px 12px ;
    }
    #headerWrap {
        width: 95%;
    }
    .header {
        background-position: 80% 0px ;
        height: 380px ;
    }
    .header > h2 {
        font-size: 2rem ;
        width: 90%;
    }
    .header > h2 > p {
        font-size: 1.2rem ;
        margin-top: 20px ;
    } 

    #lnb {
        display: none ;
    }

    .Contents {
        width: 720px ;
    }

    .Contents > div {
        margin: 120px 0px;
    }

    .Contents img {
        max-width: 720px ;
    }

    .pageInfo {
        font-size: 1.2rem !important;
        margin: 100px auto !important;
    }

    .object, .action, .face {
        margin: 150px 0px;
    }

    .tech > div > img {
        width: 400px;
    }

    .tech > div > div > p {
        font-size: 1.8rem;
    }

    .tech > div > div > span {
        font-size: 1.3rem;
    }

    .tech > div:nth-child(odd) > div > * {
        width: calc(100% - 40px);
        margin-left: 40px;
    }

    .tech > div:nth-child(even) > div > * {
        width: calc(100% - 40px);
        margin-right: 40px;
    }

    .deepCaseWrap {
        width: 720px ;
    }

    .deepUseCase {
        padding: 80px 0px;
    }

    .caseInfo > h3 {
        font-size: 1rem;
    }

    .caseBoxContainer {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .caseBox {
        padding: 20px;
        width: calc(50% - 20px);
        flex-direction: column;
        align-items: flex-start;
    }

    .caseBox > p {
        width: 100%;
    }

    .caseBox > div:first-child {
        width: 40%;
        margin-bottom: 20px;
    }

    .caseBox > div:first-child > img {
        width: 100%;
    }

    .caseBox > button {
        width: 20%;
        height: 20px;
        margin-top: 20px;
    }

    .ModalCon {
        max-width: 750px;
    }

    .ModalCon > img:first-child, .ModalCon > div:first-child > img {
        width: 30%;
    }

    .MdeeplearningProcess > h3 > .line {
        width: 75%;
    }

    .MprocessItem {
        width: 720px;
        height: 141.15px;
        text-align: left;
    }

    .MprocessItem > div > p:first-child {
        width: 25%;
    }

    .MprocessItem > div > .line {
        left: 135px;
    }

    .MprocessItem > div > p:last-child {
        margin-left: 50px;
        min-width: 425px;
        max-width: 425px;
    }

    .ReactModal__Content > img {
        width: 700px ;
    }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width:480px) and (max-width:767px) {
    header {
        padding: 17px 12px ;
    }
    #headerWrap {
        width: 95%;
    }

    .deepHeader {
        background: url(../images/M-video-deeplearning.png) no-repeat bottom / cover;
        height: 600px;
        align-items: unset;
    }

    .header > h2 {
        font-size: 2rem ;
        width: 85% ;
        margin-top: 180px;
    }

    .header > h2 > p {
        font-size: 1.1rem ;
        margin-top: 30px ;
        width: 80%;
        line-height: 1.6rem;
    } 

    #lnb {
        display: none ;
    }

    .Contents {
        width: 420px ;
    }

    .Contents > div {
        margin: 120px 0px;
    }

    .Contents img {
        max-width: 420px ;
    }

    .pageInfo {
        font-size: 1.2rem !important;
        margin: 100px auto !important;
        width: 95%;
        word-break: keep-all;
    }

    .object, .face {
        margin: 100px 0px;
        flex-direction: column;
    }

    .action {
        margin: 100px 0px;
        flex-direction: column-reverse;
    }

    .tech > div:nth-child(odd) > div > * {
        width: 100%;
        margin-left: 0px;
    }

    .tech > div:nth-child(even) > div > * {
        width: 100%;
        margin-right: 0px;
    }

    .tech > div > div > p {
        font-size: 1.5rem;
        margin-top: 20px;
    }

    .tech > div > div > span {
        font-size: 1.2rem;
    }

    .deepUseCase {
        padding: 80px 0px;
        margin: 120px 0px 120px calc(-50vw + 50%) !important;
    }

    .deepCaseWrap {
        width: 420px;
        flex-direction: column;
    }

    .caseInfo > h3 {
        font-size: 1rem;
    }

    .caseBoxContainer {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .caseBox {
        padding: 20px;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        font-size: 1.2rem;
    }

    .caseBox > p { 
        width: 100%;
    }

    .caseBox > p > span {
        font-size: 1rem;
    }

    .caseBox > div:first-child {
        width: 40%;
        margin-bottom: 20px; 
    }

    .caseBox > div:first-child > img {
        width: 100%;
    }

    .caseBox > button {
        width: 20%;
        height: 20px;
        margin-top: 20px;
        font-size: .9rem;
        text-align: left;
    }

    .ModalCon {
        max-width: 400px;
    }

    .ModalCon > img:first-child, .ModalCon > div:first-child > img {
        width: 50%;
    } 

    .ModalCon > p {
        font-size: 1.1rem;
    }

    .ModalCon > p > span {
        font-size: 1rem;
    }

    .MdeeplearningProcess > h3 {
        font-size: 1.1rem;
    }

    .MdeeplearningProcess > h3 > .line {
        width: 65%;
    }

    .MprocessItem {
        width: 420px;
        height: 180px;
        text-align: left;
        border-radius: 17px;
    }

    .MprocessItem > p {
        font-size: 1.2rem;
        margin: 54px 0px 0px 10px;
    }

    .MprocessItem > div {
        flex-direction: column;
        margin: 48px 0px 0px 45px;
    }

    .MprocessItem > div > p:first-child {
        /* width: 25%; */
        font-size: 1.2rem;
    }

    .MprocessItem > div > .line {
        position: relative;
        top: 0;
        left: 0;
        width: 20px;
        height: 5px;
        margin: 10px 0px 20px;
    }

    .MprocessItem > div > p:last-child {
        margin-left: 0px;
        font-size: 1.1rem;
            max-width: 320px;
    }

    .MprocessItem:last-child > div > p:last-child {
        margin-left: 0px;
    }
    
    .Mprocess1 {
        background: url(../images/M2-deepProcessBg1.png) no-repeat center / cover;
        box-shadow: 2px 2px 5px rgba(6, 187, 255, 0.25);
        color: #06C2FD;
    }
    
    .Mprocess2 {
        background: url(../images/M2-deepProcessBg2.png) no-repeat center / cover;
        box-shadow: 2px 2px 5px rgba(73, 28, 255, 0.25);
        color: #5343FD;
    }

    .Mprocess3 {
    background: url(../images/M2-deepProcessBg3.png) no-repeat center / cover;
    box-shadow: 2px 2px 5px rgba(176, 50, 255, 0.25);
    color: #9824EF;
    }

    .Mprocess4 {
        background: url(../images/M2-deepProcessBg4.png) no-repeat center / cover;
        box-shadow: 2px 2px 5px rgba(255, 45, 121, 0.25);
        color: #FC41A5;
    }
    .Mprocess5 {
        background: url(../images/M2-deepProcessBg5.png) no-repeat center / cover;
        box-shadow: 2px 2px 5px rgba(255, 67, 48, 0.25);
        color: #FE405B;
    }

    .ReactModal__Content > img {
        width: 450px ;
    }
}

/* 모바일  세로 (해상도 ~ 479px)*/
@media all and (max-width:479px) {
    header {
        padding: 17px 5px ;
    }
    h1 > a {
        width: 140px;
    }
    #headerWrap {
        width: 95%;
    }

    .deepHeader {
        background: url(../images/M-video-deeplearning.png) no-repeat bottom / cover;
        height: 450px;
        align-items: unset;
    }

    .header > h2 {
        font-size: 1.6rem ;
        width: 85% ;
        margin-top: 130px;
        font-weight: bold;
    }

    .header > h2 > p {
        font-size: .9rem ;
        margin-top: 30px ;
        width: 90%;
        line-height: 1.6rem;
    } 

    #lnb {
        display: none ;
    }

    .Contents {
        width: 350px ;
    }

    .Contents > div {
        margin: 120px 0px;
    }

    .Contents img {
        max-width: 350px ;
    }

    .pageInfo {
        width: 100%;
        font-size: .9rem !important;
        margin: 100px auto !important;
        word-break: keep-all;
    }

    .object, .face {
        margin: 80px 0px;
        flex-direction: column;
    }

    .action {
        margin: 80px 0px;
        flex-direction: column-reverse;
    }

    .tech > div:nth-child(odd) > div > * {
        width: 100%;
        margin-left: 0px;
    }

    .tech > div:nth-child(even) > div > * {
        width: 100%;
        margin-right: 0px;
    }

    .tech > div > div > p {
        font-size: 1.3rem;
        margin-top: 20px;
    }

    .tech > div > div > span {
        font-size: 1rem;
    }

    .deepUseCase {
        padding: 80px 0px;
        margin: 120px 0px 120px calc(-50vw + 50%) !important;
    }

    .deepCaseWrap {
        width: 350px;
        flex-direction: column;
    }

    .caseInfo > h3 {
        font-size: 1rem;
    }

    .caseBoxContainer {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .caseBox {
        padding: 20px;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        font-size: 1.1rem;
    }

    .caseBox > p { 
        width: 100%;
    }

    .caseBox > p > span {
        font-size: 1rem;
    }

    .caseBox > div:first-child {
        width: 40%;
        margin-bottom: 20px; 
    }

    .caseBox > div:first-child > img {
        width: 100%;
    }

    .caseBox > button {
        width: 20%;
        height: 20px;
        margin-top: 20px;
        font-size: .9rem;
        text-align: left;
    }

    .ModalCon {
        max-width: 300px;
    }

    .ModalCon > img:first-child, .ModalCon > div:first-child > img {
        width: 50%;
    } 

    .ModalCon > p {
        font-size: 1.1rem;
    }

    .ModalCon > p > span {
        font-size: .9rem;
    }

    .MdeeplearningProcess > h3 {
        font-size: 1.1rem;
    }

    .MdeeplearningProcess > h3 > .line {
        width: 55%;
    }

    .MprocessItem {
        width: 350px;
        height: 150px;
        text-align: left;
        border-radius: 17px;
    }

    .MprocessItem > p {
        font-size: 1.2rem;
        margin: 45px 0px 0px 6px;
    }

    .MprocessItem > div {
        flex-direction: column;
        margin: 28px 0px 0px 45px;
    }

    .MprocessItem > div > p:first-child {
        /* width: 25%; */
        font-size: 1.2rem;
    }

    .MprocessItem > div > .line {
        position: relative;
        top: 0;
        left: 0;
        width: 20px;
        height: 3px;
        margin: 10px 0px 20px;
    }

    .MprocessItem > div > p:last-child {
        margin-left: 0px;
        font-size: 1rem;
            max-width: 265px;
    }

    .MprocessItem:last-child > div > p:last-child {
        margin-left: 0px;
    }
    
    .Mprocess1 {
        background: url(../images/M2-deepProcessBg1.png) no-repeat center / cover;
        box-shadow: 2px 2px 5px rgba(6, 187, 255, 0.25);
        color: #06C2FD;
    }
    
    .Mprocess2 {
        background: url(../images/M2-deepProcessBg2.png) no-repeat center / cover;
        box-shadow: 2px 2px 5px rgba(73, 28, 255, 0.25);
        color: #5343FD;
    }

    .Mprocess3 {
    background: url(../images/M2-deepProcessBg3.png) no-repeat center / cover;
    box-shadow: 2px 2px 5px rgba(176, 50, 255, 0.25);
    color: #9824EF;
    }

    .Mprocess4 {
        background: url(../images/M2-deepProcessBg4.png) no-repeat center / cover;
        box-shadow: 2px 2px 5px rgba(255, 45, 121, 0.25);
        color: #FC41A5;
    }
    .Mprocess5 {
        background: url(../images/M2-deepProcessBg5.png) no-repeat center / cover;
        box-shadow: 2px 2px 5px rgba(255, 67, 48, 0.25);
        color: #FE405B;
    }

    .ReactModal__Content > img {
        width: 350px ;
    }
}   