.vivaHeader {
  width: 100%;
  height: 520px;
  line-height: 520px;
  color: #fff;
  text-shadow: rgba(0,0,0,0.3) 1px 1px 5px;
  background: url('../images/header-info.png') no-repeat center / cover;
}

.vivaContainer > div:not(.vivaHeader, #lnb, .footerContainer) {
    margin: 100px 0px;
    padding: 50px 0px;
}

.vivaContainer > div:nth-child(4) {
    background-color: #F1F7FF;
}

.vivaContainer h2:not(.header > h2) {
    margin: 0px auto 50px;
    font-size: 3rem;
    color: #2364BC;
    letter-spacing: .3rem;
}

.vivaContainer > div > p {
    font-size: 1.5rem;
    line-height: 3rem;
}

.subvalue > img {
    max-width: 700px;
    margin: 50px auto;
}

/* PC 2 */
@media all and (min-width: 1201px) and (max-width: 1919px) {
    .vivaHeader {
    line-height: 450px;
    background-position: center !important;
  }

    .vivaHeader > h2 {
    margin: 0 auto;
    height: 100%;
    }
}

/* PC , 테블릿 가로 (해상도 1024px ~ 1200px)*/
@media all and (min-width:1024px) and (max-width: 1200px) {
    .vivaHeader {
    line-height: 450px;
    background-position: center !important;
  }
  
    .vivaHeader > h2 {
    margin: 0 auto;
    height: 100%;
    }

    .valueIco {
        width: 900px !important;
    }
}

/* 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (min-width:768px) and (max-width:1023px) {
    .vivaHeader {
    line-height: 380px;
    background-position: center !important;
  }

  .vivaHeader > h2 {
    margin: 0 auto;
    height: 100%;
  }

  .valueIco {
        width: 700PX !important;
    }

    .valueLine {
        width: 50px !important;
    }

    .valueIco > div > div {
        width: 100px;
        height: 100px;
        line-height: 100px;
    }

    .valueImg {
        width: 70px !important;
    }

    .valueIco > div > p:nth-child(2) {
        font-size: 1.1rem !important;
        line-height: 1rem !important;
    }

    .valueIco > div > p:nth-child(3) {
        font-size: 1.8rem !important;
    }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/ 
@media all and (min-width:480px) and (max-width:767px) {
    .vivaHeader {
    background: url(../images/M-info.png) no-repeat bottom / cover;
    height: 400px;
    line-height: 400px;
    align-items: unset;
  }

  .vivaContainer > div:not(.vivaHeader, #lnb, .footerContainer) {
    margin: 50px auto;
  }

  .vivaHeader > h2 {
    margin: 0 auto !important;
  }

  .vivaContainer h2:not(.header > h2) {
    font-size: 2rem
  }

  .vivaContainer > div > p {
    font-size: 1rem;
    line-height: 2rem;
  }
  
  .valueIco {
        width: 450px !important;
        height: 850px;
        flex-direction: column;
        align-items: center!important;
    }

    .valueLine {
        width: 1px !important;
        height: 50px !important;
    }


    .valueIco > div > p:nth-child(2) {
        font-size: 1.1rem !important;
        line-height: 1rem !important;
    }

    .valueIco > div > p:nth-child(3) {
        font-size: 1.8rem !important;
    }
}

/* 모바일  세로 (해상도 ~ 479px)*/
@media all and (max-width:479px) {
    .vivaHeader {
    background: url(../images/M-info.png) no-repeat bottom / cover;
    height: 400px;
    line-height: 400px;
    align-items: unset;
  }

  .vivaContainer > div:not(.vivaHeader, #lnb, .footerContainer) {
    margin: 50px auto;
  }

  .vivaHeader > h2 {
    margin: 0 auto !important;
  }

  .vivaContainer h2:not(.header > h2) {
    font-size: 2rem
  }

  .vivaContainer > div > p {
    font-size: 1rem;
    line-height: 2rem;
  }
  
  .valueIco {
        width: 300px !important;
        height: 850px;
        flex-direction: column;
        align-items: center!important;
    }

    .valueLine {
        width: 1px !important;
        height: 50px !important;
    }


    .valueIco > div > p:nth-child(2) {
        font-size: 1.1rem !important;
        line-height: 1rem !important;
    }

    .valueIco > div > p:nth-child(3) {
        font-size: 1.8rem !important;
    }
}