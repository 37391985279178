* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.2s;
  word-break: keep-all;
}

body {
  font-family: "SUIT";
}

header {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 17px 100px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s;
  z-index: 9999;
}

#headerWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
}

h1 {
  height: 45.6px;
}

h1 > a {
  display: inline-block;
  width: 180px;
  height: 45.6px;
  background: url("./images/logo-w.png") no-repeat center / contain;
}

nav {
  width: calc(100% - 350px);
}

#gnb {
  display: flex;
  justify-content: flex-end;
  height: 38px;
}

#gnb li {
  margin: 0px 30px;
  line-height: 38px;
}

.defaultNav a {
  color: #efefef;
  font-size: 1.2rem;
  transition: all .2s;
  display: inline-block;
}

.mBurger {
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  cursor: pointer;
}

.mBurger > div {
  width: 100%;
  height: 4px;
  background-color: #fff;
  border-radius: 100px;
  position: relative;
  transition: all 0.5s;
}

.showNav:first-child {
  transform: rotate(-135deg);
  top: 8px;
}

.showNav:nth-child(2) {
  display: none;
}

.showNav:last-child {
  transform: rotate(135deg);
  top: -8px;
}

.main {
  background-color: #fff;
}

.main h1 > a {
  background: url("./images/logo-B.png") no-repeat center / contain;
}

.main nav a {
  color: #333;
}

.pages:hover {
  background-color: #fff;
}

.pages:hover h1 > a {
  background: url("./images/logo-B.png") no-repeat center / contain;
}

.pages:hover nav a {
  color: #333;
}

header:hover nav a:hover {
  color: #2364bc;
}

header:hover .mBurger > div,
.main .mBurger > div {
  background-color: #333;
}

.mNav {
  position: absolute;
  width: 60%;
  height: 100vh;
  margin: 0;
  top: 80px;
  right: 0;
  border-radius: 0px;
  background-color: #fff;
  color: #333;
}

.mNav > ul {
  position: relative;
  top: calc(50% - 25%);
  font-size: 1.5rem;
}

.mNav > ul > li {
  padding: 20px 0px;
}

.mNav > ul > li > a {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 20px;
}

.mNav > ul > li > a:hover {
  color: #2364bc;
}

/* .mNav> ul > li:hover > ul {
  display: block;
} */

.mDep2 {
  font-size: 1.1rem;
  /* opacity: 0; */
  transition: all 0.2s;
  /* visibility: hidden; */
  display: none;
}

.mDep2 > li {
  padding: 15px 0px;
}

.mDep2 > li > a {
  display: inline-block;
}

.mDep2 > li:hover > a {
  color: #2364bc;
}

#active {
  opacity: 1;
  cursor: pointer;
  display: block;
}

.topBtn {
  width: 50px;
  height: 50px;
  background: url("./images/top.png");
  position: fixed;
  right: 50px;
  bottom: 50px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 999;
}

.topBtn.on {
  opacity: 1;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.3) !important;
  z-index: 99999;
}

.ReactModal__Content {
  max-height: 85%;
  overflow-y: scroll;
}

.ReactModal__Content > img {
  width: 1200px;
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  .mNav {
    width: 100%;
  }

  .topBtn {
    right: 40px;
    bottom: 40px;
  }
}

/* 모바일  세로 (해상도 ~ 479px)*/
@media all and (max-width: 479px) {
  .topBtn {
    right: 30px;
    bottom: 30px;
  }

  .mNav > ul {
    top: 20px;
  }
}
