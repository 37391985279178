.hisHeader {
  width: 100%;
  height: 520px;
  line-height: 520px;
  color: #fff;
  text-shadow: rgba(0,0,0,0.3) 1px 1px 5px;
  background: url('../images/header-info.png') no-repeat center / cover;
}

.hisContainer {
  overflow: hidden;
}

.hisContainer > div:not(.hisHeader, #lnb, .footerContainer, .history) {
    margin: 100px 0px;
    padding: 50px 0px;
}

.hisContainer h2:not(.header > h2) {
    margin: 0px auto 50px;
    font-size: 3rem;
    color: #2364BC;
    letter-spacing: .3rem;
}

.history {
  width: 1200px;
  margin: 100px auto;
  padding: 50px 0px;
}

.history > h2 > p {
  text-align: center;
  margin: 0 auto;
}

.hisCopy {
  font-size: 2rem;
  margin-top: 100px;
  text-align: left;
}

.hisCopy > span {
  display: block;
  font-weight: 600;
}

.imgBox {
  width: 100vw;
  height: 500px;
  margin: 150px 0px 150px calc(-50vw + 50%) !important;
  background: url(../images/sign.png) no-repeat 0px 30% / cover;
}

.hisBox {
  margin-top: 20px;
}

.hisBox > div {
  display: flex;
  width: 100%;
  margin: 0px auto;
  font-size: 2rem;
  text-align: left; 
  padding: 50px 0px;
  border-top: 1px solid #ececec;
}

.hisBox > div > p {
  width: 15%;
  font-weight: 600;
}

.hisBox > div:last-child {
  border-bottom: 1px solid #ececec;
}

.hisBox > div > span {
  font-size: 1.2rem;
  line-height: 2rem;
}

/* PC 2 */
@media all and (min-width: 1201px) and (max-width: 1919px) {
  .hisHeader {
    line-height: 450px;
    background-position: center !important;
  }
  
  .hisHeader > h2 {
    margin: 0 auto;
    height: 100%;
    }

    .history {
      width: 1000px;
    }

    /* .hisCopy {
      margin-left: 40px;
    }

    .hisBox {
      margin: 20px 40px 0px;
    } */
}

/* PC , 테블릿 가로 (해상도 1024px ~ 1200px)*/
@media all and (min-width:1024px) and (max-width: 1200px) {
  .hisHeader {
    line-height: 450px;
    background-position: center !important;
  }

  .hisHeader > h2 {
    margin: 0 auto;
    height: 100%;
    }

    .history {
      width: 900px;
    }

    .imgBox {
      height: 400px;
    }
}

/* 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (min-width:768px) and (max-width:1023px) {
  .hisHeader {
    line-height: 380px;
    background-position: center !important;
  }

  .hisHeader > h2 {
    margin: 0 auto;
    height: 100%;
    }

    .history {
      width: 600px;
    }

    .imgBox {
      height: 350px;
    }

    .hisCopy {
      font-size: 1.5rem;
    }

    .hisBox > div {
      font-size: 1.5rem;
    }

    .hisBox > div > span {
      font-size: 1.1rem;
      line-height: 1.7rem;
    }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/ 
@media all and (min-width:480px) and (max-width:767px) {
  .hisHeader {
    background: url(../images/M-info.png) no-repeat bottom / cover;
    height: 400px;
    line-height: 400px;
    align-items: unset;
  }

  .hisHeader > h2 {
    margin: 0 auto !important;
  }

  .hisContainer h2:not(.header > h2) {
    font-size: 2rem;
  }

  .InfoContainer > div > p {
    font-size: 1rem;
    line-height: 2rem;
  }

    .history {
      width: 400px;
    }

    .imgBox {
      height: 200px;
      margin: 100px 0px 100px calc(-50vw + 50%) !important;
    }

    .hisCopy {
      font-size: 1.2rem;
    }

    .hisBox > div {
      font-size: 1.2rem;
    }

    .hisBox > div > p {
      width: 20%;
    }

    .hisBox > div > span {
      font-size: .9rem;
      line-height: 1.5rem;
    }
}

/* 모바일  세로 (해상도 ~ 479px)*/
@media all and (max-width:479px) {
  .hisHeader {
    background: url(../images/M-info.png) no-repeat bottom / cover;
    height: 400px;
    line-height: 400px;
    align-items: unset;
  }

  .hisHeader > h2 {
    margin: 0 auto !important;
  }

  .hisContainer h2:not(.header > h2) {
    font-size: 2rem
  }

  .InfoContainer > div > p {
    font-size: 1rem;
    line-height: 2rem;
  }

    .history {
      width: 350px;
    }

    .imgBox {
      height: 200px;
      margin: 100px 0px 100px calc(-50vw + 50%) !important;
    }

    .hisCopy {
      font-size: 1.2rem;
    }

    .hisBox > div {
      font-size: 1.2rem;
    }

    .hisBox > div > p {
      width: 20%;
    }

    .hisBox > div > span {
      font-size: .9rem;
      line-height: 1.5rem;
    }
}