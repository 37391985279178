.digitalContainer {
    overflow: hidden;
}

.digiHeader {
    width: 100%;
    height: 520px;
    color: #fff;
    text-shadow: rgba(0,0,0,0.3) 1px 1px 5px;
    background: url('../images/header-digitaltwin.png') no-repeat center / cover;
    display: flex;
    align-items: center;
}

.digiHeader > * {
    width: 1200px;
    margin: 0 auto;
    text-align: left;
}

.digitalCon {
    width: 1200px;
    margin: 0 auto;
    position: relative;
}

.digitalCon > div {
    margin: 150px 0px;
}

.pageInfo {
    font-size: 1.5rem;
    margin: 200px auto;
    word-break: keep-all;
    width: 80%;
}

.pageInfo > span {
    font-weight: bold;
}

.digitalIcoSec {
    display: flex;
    justify-content: space-between;
}

.digitalIcoSec > div > p {
    margin-top: 20px;
    font-size: 1.5rem;
    font-weight: 600;
}

.digitalIcoSec > div > p > span {
    margin-top: 10px;
    font-size: 1.3rem;
    font-weight: normal;
    line-height: 1.6rem;
    display: inline-block;
}

.digitalUseExam > h3 {
    font-size: 2rem;
}

.digitalUseExam > .line {
    width: 1px;
    height: 200px;
    background-color: #3483ED;
    margin: 60px auto;
}

.digitalExamWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 722px;
    align-content: space-between;
}


.digitalExamWrap > div > p {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: left;
}

.digitalExamWrap > div > p > span {
    font-size: 1.3rem;
    font-weight: normal;
    display: inline-block;
    margin: 10px 0px 20px;
}

.digitalExamWrap > div:last-child > p {
    margin-bottom: 55.5px;
}

.digitalUseCase {
    padding: 100px 0px;
    background-color: #F1F7FF;
    width: 100vw;
    margin: 150px 0px 150px calc(-50vw + 50%) !important;
}

.digitalCaseWrap {
    width: 1200px;
    margin: 0 auto;
}

.digitalCaseWrap h3 {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: left;
}

.digitalCaseWrap .caseSlide {
    width: 1200px;
    height: 633px;
    padding: 10px;
    border: 3px solid #3483ED;
    margin: 40px 0px;
}

.digitalCaseWrap .caseSlide > img {
    width: 1175px;
}

.caseItem {
    display: flex;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 20px;
}

.caseItem > p {
    font-size: 1.5rem;
}

.caseItem > div {
    display: flex;
    align-items: center;
}

.caseItem > div > .line {
    width: 5px;
    height: 25px;
    background-color: #333;
    display: inline-block;
    margin-right: 10px;
}

.caseItem > div > p {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 600;
    margin-right: 20px;
}

/* PC 2 */
@media all and (min-width: 1201px) and (max-width: 1919px) {
    #headerWrap {
        width: 1000px;
    }
    .header {
        background-position: 80% 0px ;
        height: 450px ;
    }
    .header > * {
        width: 1000px ;
    }
    .header > h2 {
        font-size: 2.3rem ;
    }
    .header > h2 > p {
        font-size: 1.2rem ;
        margin-top: 40px ;
    } 

    #lnb a {
        font-size: .9rem ;
    }

    .Contents {
        width: 1000px ;
    }

    .Contents img {
        max-width: 1000px ;
    }

    .pageInfo {
        font-size: 1.3rem ;
        margin: 150px auto;
    }

    .IcoSec > div > img {
        width: 80px;
    }

    .IcoSec > div > p {
        font-size: 1.3rem ;
    }

    .IcoSec > div > p > span {
        font-size: 1.1rem ;
        line-height: 1.5rem ;
    }

    .UseExam > h3 {
        font-size: 1.8rem ;
    }

    .UseExam > .line {
        height: 150px ;
    }

    .ExamWrap {
        min-height: 611px ;
    }

    .ExamWrap > div > img {
        width: 480px;
    }

    .ExamWrap > div > p {
        font-size: 1.3rem ;
    }

    .ExamWrap > div > p > span {
        font-size: 1.2rem ;
    }

    .ExamWrap > div:last-child > p {
        margin-bottom: 54px ;
    }

    .CaseWrap {
        width: 1000px ;
    }

    .digitalCaseWrap .caseSlide {
        width: 1000px ;
        height: 533px ;
    }

    .digitalCaseWrap .caseSlide > img {
        width:  975px ;
    }

    .caseItem > img {
        width: 120px;
    }

    .caseItem > p {
        font-size: 1.3rem !important;
    }

    .ReactModal__Content > img {
        width: 1000px ;
    }
}

/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (min-width:1024px) and (max-width: 1200px) { 
    header {
        padding: 17px 12px ;
    }
    #headerWrap {
        width: 1000px;
    }
    .header {
        background-position: 80% 0px ;
        height: 450px ;
    }
    .header > * {
        width: 900px ;
    }
    h2 {
        font-size: 2.3rem ;
    }
    h2 > p {
        font-size: 1.2rem ;
        margin-top: 40px ;
    } 

    #lnb {
        display: none ;
    }

    .Contents {
        width: 900px ;
    }

    .Contents img {
        max-width: 900px ;
    }

    .pageInfo {
        font-size: 1.3rem ;
        margin: 150px auto;
    }

    .IcoSec > div > img {
        width: 80px;
    }

    .IcoSec > div > p {
        font-size: 1.3rem ;
    }

    .IcoSec > div > p > span {
        font-size: 1.1rem ;
        line-height: 1.5rem ;
    }

    .UseExam > h3 {
        font-size: 1.8rem ;
    }

    .UseExam > .line {
        height: 150px ;
    }

    .digitalExamWrap {
        min-height: 591px ;
    }

    .ExamWrap > div > img {
        width: 430px;
    }

    .ExamWrap > div > p {
        font-size: 1.3rem ;
    }

    .ExamWrap > div > p > span {
        font-size: 1.2rem ;
    }

    .ExamWrap > div:last-child > p {
        margin-bottom: 54px ;
    }

    .CaseWrap {
        width: 900px ;
    }

    .digitalCaseWrap .caseSlide {
        width: 900px ;
        height: 480px ;
    }

    .digitalCaseWrap .caseSlide > img {
        width:  875px ;
    }

    .caseItem > img {
        width: 120px;
    }

    .caseItem > p {
        font-size: 1.3rem !important;
    }

    .ReactModal__Content > img {
        width: 950px ;
    }
}

/* 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (min-width:768px) and (max-width:1023px) { 
    header {
        padding: 17px 12px ;
    }
    #headerWrap {
        width: 95%;
    }
    .header {
        background-position: 80% 0px ;
        height: 380px ;
    }
    .header > h2 {
        font-size: 2rem ;
        width: 90% ;
    }
    .header > h2 > p {
        font-size: 1.2rem ;
        margin-top: 20px ;
    } 

    #lnb {
        display: none ;
    }

    .Contents {
        width: 720px ;
    }

    .Contents > div {
        margin: 120px 0px;
    }

    .Contents img {
        max-width: 720px ;
    }

    .pageInfo {
        font-size: 1.2rem ;
        margin: 100px auto;
    }

    .IcoSec {
        flex-direction: column ;
    }

    .IcoSec > div {
        margin-bottom: 40px;
    }

    .IcoSec > div:last-child {
        margin-bottom: 0;
    }

    .IcoSec > div > img {
        width: 80px;
    }

    .IcoSec > div > p {
        font-size: 1.5rem ;
    }

    .IcoSec > div > p > span {
        font-size: 1.2rem ;
        line-height: 1.5rem ;
    }

    .UseExam > h3 {
        font-size: 1.8rem ;
    }

    .UseExam > .line {
        height: 150px ;
    }

    .ExamWrap {
        flex-direction: column;
        align-content: center ;
        justify-content: space-between ;
    }

    .digitalExamWrap {
        min-height: 1252.52px ;
    }

    .ExamWrap > div > img {
        width: 500px;
    }

    .ExamWrap > div > p {
        font-size: 1.3rem ;
    }

    .ExamWrap > div > p > span {
        font-size: 1.2rem ;
    }

    .ExamWrap > div:last-child > p {
        margin-bottom: 54px ;
    }

    .UseCase {
        padding: 80px 0px ;
        margin: 120px 0px 120px calc(-50vw + 50%) !important;
    }

    .CaseWrap {
        width: 720px ;
        padding: 0px 40px;
    }

    .digitalCaseWrap .caseSlide {
        width: 100% ;
        height: 345px ;
        margin: 20px 0px ;
    }

    .digitalCaseWrap .caseSlide > img {
        width:  610px ;
    }

    .caseItem > img {
        width: 120px;
    }

    .caseItem > p {
        font-size: 1.3rem !important;
    }

    .ReactModal__Content > img {
        width: 750px;
    }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width:480px) and (max-width:767px) {
    header {
        padding: 17px 12px ;
    }
    #headerWrap {
        width: 95%;
    }

    .digiHeader {
        background: url(../images/M-digitaltwin.png) no-repeat bottom / cover;
        height: 600px;
        align-items: unset;
    }

    .header > h2 {
        font-size: 2rem ;
        width: 85% ;
        margin-top: 180px;
    }

    .header > h2 > p {
        font-size: 1.1rem ;
        margin-top: 20px ;
        width: 80%;
        line-height: 1.6rem;
    } 

    #lnb {
        display: none ;
    }

    .Contents {
        width: 450px ;
    }

    .Contents > div {
        margin: 120px 0px;
    }

    .Contents img {
        max-width: 450px ;
    }

    .pageInfo {
        font-size: 1.2rem !important;
        margin: 100px auto !important;
        width: 95%;
        word-break: keep-all;
    }

    .IcoSec {
        flex-direction: column ;
    }

    .IcoSec > div {
        margin-bottom: 40px;
    }

    .IcoSec > div:last-child {
        margin-bottom: 0;
    }

    .IcoSec > div > img {
        width: 80px;
    }

    .IcoSec > div > p {
        font-size: 1.5rem ;
    }

    .IcoSec > div > p > span {
        font-size: 1.2rem ;
        line-height: 1.5rem ;
    }

    .UseExam > h3 {
        font-size: 1.8rem ;
    }

    .UseExam > .line {
        height: 150px ;
    }

    .ExamWrap {
        flex-direction: column;
        align-content: center ;
        justify-content: space-between ;
    }

    .digitalExamWrap {
        min-height: 1252.52px ;
    }

    .ExamWrap > div > img {
        width: 450px;
    }

    .ExamWrap > div > p {
        font-size: 1.3rem ;
    }

    .ExamWrap > div > p > span {
        font-size: 1.2rem ;
    }

    .ExamWrap > div:last-child > p {
        margin-bottom: 54px ;
    }

    .UseCase {
        padding: 60px 0px;
        margin: 120px 0px 120px calc(-50vw + 50%) !important;
    }

    .CaseWrap {
        width: 450px;
        padding: 0px 20px;
    }

    .digitalCaseWrap .caseSlide {
        width: 100% ;
        height: 225px ;
        margin: 20px 0px ;
    }

    .digitalCaseWrap .caseSlide > img {
        width:  380px ;
    }

    .caseItem > img {
        width: 100px;
    }

    .caseItem > p {
        font-size: 1.2rem !important;
    }

    .caseItem > div > p {
        font-size: 1.3rem !important;
    }

    .caseItem > div > .line {
        height: 22px !important;
    }

    .ReactModal__Content > img {
        width: 450px ;
    }
}

/* 모바일  세로 (해상도 ~ 479px)*/
@media all and (max-width:479px) {
    header {
        padding: 17px 5px ;
    }
    h1 > a {
        width: 140px;
    }
    #headerWrap {
        width: 95%;
    }

    .digiHeader {
        background: url(../images/M-digitaltwin.png) no-repeat bottom / cover;
        height: 450px;
        align-items: unset;
    }

    .header > h2 {
        font-size: 1.6rem ;
        width: 85% ;
        margin-top: 130px;
        font-weight: bold;
    }

    .header > h2 > p {
        font-size: .9rem ;
        margin-top: 30px ;
        width: 90%;
        line-height: 1.6rem;
    } 

    #lnb {
        display: none ;
    }

    .Contents {
        width: 350px ;
    }

    .Contents > div {
        margin: 120px 0px;
    }

    .Contents img {
        max-width: 350px ;
    }

    .pageInfo {
        width: 100%;
        font-size: .9rem !important;
        margin: 100px auto !important;
        word-break: keep-all;
    }

    .IcoSec {
        flex-direction: column ;
    }

    .IcoSec > div {
        margin-bottom: 60px;
    }

    .IcoSec > div:last-child {
        margin-bottom: 0;
    }

    .IcoSec > div > img {
        width: 60px;
    }

    .IcoSec > div > p {
        font-size: 1.2rem ;
    }

    .IcoSec > div > p > span {
        font-size: 1rem ;
        line-height: 1.3rem ;
    }

    .UseExam > h3 {
        font-size: 1.3rem ;
    }

    .UseExam > .line {
        height: 100px ;
        margin: 40px auto;
    }

    .ExamWrap {
        flex-direction: column;
        align-content: center ;
        justify-content: space-between ;
    }

    .digitalExamWrap {
        min-height: 811.88px ;
    }

    .ExamWrap > div > img {
        width: 300px;
    }

    .ExamWrap > div > p {
        font-size: 1.1rem ;
    }

    .ExamWrap > div > p > span {
        font-size: 1rem ;
        margin: 5px 0px 10px;
    }

    .ExamWrap > div:last-child > p {
        margin-bottom: 35px ;
    }

    .UseCase {
        padding: 60px 0px;
        margin: 120px 0px 120px calc(-50vw + 50%) !important;
    }

    .CaseWrap {
        width: 350px;
        padding: 0px 20px;
    }

    .CaseWrap > h3 {
        font-size: 1rem;
    }

    .digitalCaseWrap .caseSlide {
        width: 100% ;
        height: 174px ;
        margin: 20px 0px ;
    }

    .digitalCaseWrap .caseSlide > img {
        width:  280px ;
    }

    .caseItem > img {
        width: 90px;
    }

    .caseItem > p {
        font-size: .9rem !important;
    }

    .caseItem > div > p {
        font-size: 1.1rem !important;
    }

    .caseItem > div > .line {
        height: 22px !important;
    }

    .ReactModal__Content > img {
        width: 350px ;
    }
}