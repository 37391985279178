#lnb {
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #aaa;
  display: flex;
  justify-content: center;
}

#lnb a {
  display: inline-block;
  width: 15%;
  min-width: 200px;
  height: calc(100% - 40px);
  line-height: 40px;
  font-size: 1.2rem;
  color: #aaa;
  border-right: 1px solid #aaa;
  margin: 20px 0px;
}

#lnb > div {
  position: relative;
  width: 15%;
  min-width: 200px;
}

#lnb > div:last-child a {
  border-right: none;
}

#lnb > a.on {
  font-weight: bold;
  color: #333;
  border-bottom: 5px solid #2364bc;
}

.comTop {
  width: 100%;
  height: 300px;
  line-height: 300px;
  font-weight: bold;
  font-size: 2.5rem;
  color: #fff;
  text-shadow: rgba(0, 0, 0, 0.3) 1px 1px 5px;
  background: url("../images/comTop.png") no-repeat center / cover;
}

#lnb div.on {
  font-weight: bold;
  color: #333;
  width: 100%;
  height: 5px;
  background-color: #2364bc;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
}

/* .topBtn {
    width: 50px;
    height: 50px;
    background: url("../images/top.png");
    position: fixed;
    right: 50px;
    bottom: 50px;
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.3);
    cursor: pointer;
    opacity: 0;
    transition: opacity .2s;
}

.topBtn.on {
    opacity: 1;
} */

::-webkit-scrollbar {
  width: 10px;
} /* 스크롤 바 */

::-webkit-scrollbar-track {
  background-color: #fff;
} /* 스크롤 바 밑의 배경 */

::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 10px;
  transition: all 0.2s;
} /* 실질적 스크롤 바 */

::-webkit-scrollbar-thumb:hover {
  background: #aaa;
} /* 실질적 스크롤 바 위에 마우스를 올려다 둘 때 */

::-webkit-scrollbar-thumb:active {
  background: #888;
} /* 실질적 스크롤 바를 클릭할 때 */

::-webkit-scrollbar-button {
  display: none;
} /* 스크롤 바 상 하단 버튼 */
