.portContainer {
    overflow: hidden;
}

.portHeader {
    width: 100%;
    height: 520px;
    color: #fff;
    text-shadow: rgba(0,0,0,0.3) 1px 1px 5px;
    background: url('../images/header-port.png') no-repeat center / cover;
    display: flex;
    align-items: center;
}

.portHeader > * {
    width: 1200px;
    margin: 0 auto;
    text-align: left;
}

.portCon {
    width: 1200px;
    margin: 0 auto;
    position: relative;
}

.portCon > div {
    margin: 150px 0px
}

.pageInfo {
    font-size: 1.5rem;
    margin: 200px auto;
}

.pageInfo > span {
    font-weight: bold;
}

.portIcoSec {
    display: flex;
    justify-content: space-around;
}

.portIcoSec > div > p {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 40px;
}

.portImgSec {
    display: flex;
    justify-content: space-between;
}

.portImgSec > div {
    height: 350px;
    width: 390px;
    border-radius: 20px;
    box-shadow: 0px 3px 10px rgba(52, 131, 237, 0.15);
}

.portImgSec > div > div {
    width: 100%;
}

.imgBox1 > div:first-child {
    background: url(../images/portImg1.png) no-repeat center / cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 50%;
}

.imgBox2 > div:first-child {
    background: url(../images/portImg2.png) no-repeat center / cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 50%;
}

.imgBox3 > div:first-child {
    background: url(../images/portImg3.png) no-repeat center / cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 50%;
}

.portImgSec > div > div:last-child {
    background-color: #F1F7FF;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 25px 30px 0px;
    height: 50%;
}

.portImgSec > div > div:last-child > p {
    font-size: 1.4rem;
    font-weight: 600;
}

.portImgSec > div > div:last-child > span {
    font-size: 1.3rem;
    margin-top: 20px;
    display: inline-block;
    word-break: keep-all;
}

.portUseCase {
    padding: 100px 0px;
    background-color: #F1F7FF;
    width: 100vw;
    margin: 150px 0px 150px calc(-50vw + 50%) !important;
}

.portCaseWrap {
    width: 1200px;
    margin: 0 auto;
}

.portCaseWrap > h3 {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: left;
}

.portCaseWrap > .caseSlide {
    width: 1200px;
    height: 373px;
    padding: 10px;
    border: 3px solid #3483ED;
    margin: 40px 0px;
}

.portCaseWrap > .caseSlide > img {
    width: 1175px;
}

.caseItem {
    display: flex;
    align-items: flex-start;
    text-align: left;
}

.caseItem:nth-child(3) {
    margin-bottom: 20px;
}

.caseItem > p {
    font-size: 1.5rem;
    word-break: keep-all;
}

.caseItem > div {
    display: flex;
    align-items: center;
}

.caseItem > div > .line {
    width: 5px;
    height: 25px;
    background-color: #333;
    display: inline-block;
    margin-right: 10px;
}

.caseItem > div > p {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 600;
    margin-right: 20px;
}

/* PC 2 */
@media all and (min-width: 1201px) and (max-width: 1919px) {
    #headerWrap {
        width: 1000px;
    }
    .portHeader {
        background-position: 90% 0px ;
        height: 450px ;
    }
    .header > * {
        width: 1000px ;
    }
    .header > h2 {
        font-size: 2.3rem ;
    }
    .header > h2 > p {
        font-size: 1.2rem ;
        margin-top: 40px ;
    } 

    #lnb a {
        font-size: .9rem ;
    }

    .Contents {
        width: 1000px ;
    }

    .pageInfo {
        font-size: 1.3rem ;
        margin: 150px auto ;
    }

    .IcoSec > div > img {
        width: 90px;
    }

    .IcoSec > div > p {
        font-size: 1.3rem ;
    }

    .IcoSec > div > p > span {
        font-size: 1.1rem ;
        line-height: 1.5rem ;
    }

    .portImgSec > div {
        width: 307px;
        height: 400px;
    }

    .portImgSec > div > div:first-child {
        height: 45%;
    }

    .portImgSec > div > div:last-child {
        height: 55%;
    }

    .CaseWrap {
        width: 1000px ;
    }

    .portCaseWrap > .caseSlide {
        width: 100% ;
        height: 313px ;
    }

    .portCaseWrap > .caseSlide > img {
        width:  975px ;
    }

    .caseItem > img {
        width: 120px;
    }

    .caseItem > p {
        font-size: 1.3rem !important;
    }

    .ReactModal__Content > img {
        width: 1000px ;
    }
}

/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (min-width:1024px) and (max-width: 1200px) { 
    header {
        padding: 17px 12px ;
    }
    #headerWrap {
        width: 1000px;
    }
    .portHeader {
        background-position: 95% 0px ;
        height: 450px ;
    }
    .header > * {
        width: 900px ;
    }
    h2 {
        font-size: 2.3rem ;
    }
    h2 > p {
        font-size: 1.2rem ;
        margin-top: 40px ;
    } 

    #lnb {
        display: none ;
    }

    .Contents {
        width: 900px ;
    }

    .Contents img {
        max-width: 900px ;
    }

    .pageInfo {
        font-size: 1.3rem ;
        margin: 150px auto;
    }

    .IcoSec > div > img {
        width: 90px;
    }

    .IcoSec > div > p {
        font-size: 1.3rem ;
    }

    .IcoSec > div > p > span {
        font-size: 1.1rem ;
        line-height: 1.5rem ;
    }
    
    .portImgSec > div {
        width: 273.34px;
        height: 400px;
    }

    .portImgSec > div > div:first-child {
        height: 50%;
    }

    .portImgSec > div > div:last-child {
        height: 50%;
    }

    .portImgSec > div > div:last-child > span {
        font-size: 1.1rem;
    }

    .CaseWrap {
        width: 900px ;
    }

    .portCaseWrap > .caseSlide {
        width: 100% ;
        height: 285px ;
    }

    .portCaseWrap > .caseSlide > img {
        width:  875px ;
    }

    .caseItem > img {
        width: 120px;
    }

    .caseItem > p {
        font-size: 1.3rem !important;
    }

    .ReactModal__Content > img {
        width: 950px ;
    }
}

/* 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (min-width:768px) and (max-width:1023px) { 
    header {
        padding: 17px 12px ;
    }
    #headerWrap {
        width: 95%;
    }
    .portHeader {
        background-position: 95% 0px ;
        height: 380px ;
    }
    .header > h2 {
        font-size: 2rem ;
        width: 90% ;
    }
    .header > h2 > p {
        font-size: 1.2rem ;
        margin-top: 20px ;
    } 

    #lnb {
        display: none ;
    }

    .Contents {
        width: 720px ;
    }

    .Contents > div {
        margin: 120px 0px;
    }

    .Contents img {
        max-width: 720px ;
    }

    .pageInfo {
        font-size: 1.2rem ;
        margin: 100px auto;
    }

    .portIcoSec {
        flex-direction: row;
    }

    .IcoSec > div {
        margin-bottom: 40px;
    }

    .IcoSec > div:last-child {
        margin-bottom: 0;
    }

    .IcoSec > div > img {
        width: 80px;
    }

    .IcoSec > div > p {
        font-size: 1.5rem ;
    }

    .IcoSec > div > p > span {
        font-size: 1.2rem ;
        line-height: 1.5rem ;
    }

    .portImgSec {
        flex-direction: column;
        align-items: center;
    }

    .portImgSec > div {
        width: 90%;
        margin-bottom: 40px;
    }

    .portImgSec > div:last-child {
        margin-bottom: 0;
    }

    .portImgSec > div > div:first-child {
        height: 65%;
    }

    .portImgSec > div > div:last-child {
        height: 35%;
    } 

    .portImgSec > div > div:last-child > p {
        font-size: 1.2rem;
    }

    .portImgSec > div > div:last-child > span {
        font-size: 1.1rem;
    }

    .UseCase {
        padding: 80px 0px ;
        margin: 120px 0px 120px calc(-50vw + 50%) !important;
    }

    .CaseWrap {
        width: 720px ;
        padding: 0px 40px;
    }

    .portCaseWrap > .caseSlide {
        width: 100% ;
        height: 206px ;
        margin: 20px 0px ;
    }

    .portCaseWrap > .caseSlide > img {
        width:  610px ;
    }

    .caseItem > img {
        width: 120px;
    }

    .caseItem > p {
        font-size: 1.3rem !important;
    }

    .ReactModal__Content > img {
        width: 750px;
    }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width:480px) and (max-width:767px) {
    header {
        padding: 17px 12px ;
    }
    #headerWrap {
        width: 95%;
    }

    .portHeader {
        background: url(../images/M-port-monitoring.png) no-repeat bottom / cover;
        height: 600px;
        align-items: unset;
    }

    .header > h2 {
        font-size: 2rem ;
        width: 85% ;
        margin-top: 180px;
    }

    .header > h2 > p {
        font-size: 1.1rem ;
        margin-top: 20px ;
        width: 80%;
        line-height: 1.6rem;
    } 

    #lnb {
        display: none ;
    }

    .Contents {
        width: 450px ;
    }

    .Contents > div {
        margin: 120px 0px;
    }

    .Contents img {
        max-width: 450px ;
    }

    .pageInfo {
        font-size: 1.2rem !important;
        margin: 100px auto !important;
        width: 95%;
        word-break: keep-all;
    }

    .IcoSec {
        flex-direction: column ;
    }

    .IcoSec > div {
        margin-bottom: 40px;
    }

    .IcoSec > div:last-child {
        margin-bottom: 0;
    }

    .IcoSec > div > img {
        width: 80px;
    }

    .IcoSec > div > p {
        font-size: 1.5rem ;
    }

    .IcoSec > div > p > span {
        font-size: 1.2rem ;
        line-height: 1.5rem ;
    }

    .portImgSec {
        flex-direction: column;
        align-items: center;
    }

    .portImgSec > div {
        width: 90%;
        margin-bottom: 40px;
    }

    .portImgSec > div:last-child {
        margin-bottom: 0;
    }

    .portImgSec > div > div:first-child {
        height: 65%;
    }

    .portImgSec > div > div:last-child {
        height: 35%;
    } 

    .portImgSec > div > div:last-child > p {
        font-size: 1.2rem;
    }

    .portImgSec > div > div:last-child > span {
        font-size: 1.1rem;
    }

    .UseCase {
        padding: 60px 0px;
        margin: 120px 0px 120px calc(-50vw + 50%) !important;
    }

    .CaseWrap {
        width: 450px;
        padding: 0px 20px;
    }

    .portCaseWrap > .caseSlide {
        width: 100% ;
        height: 138px ;
        margin: 20px 0px ;
    }

    .portCaseWrap > .caseSlide > img {
        width:  380px ;
    }

    .caseItem > img {
        width: 100px;
    }

    .caseItem > p {
        font-size: 1.2rem !important;
    }

    .caseItem > div > p {
        font-size: 1.3rem !important;
    }

    .caseItem > div > .line {
        height: 22px !important;
    }

    .ReactModal__Content > img {
        width: 450px ;
    }
}

/* 모바일  세로 (해상도 ~ 479px)*/
@media all and (max-width:479px) {
    header {
        padding: 17px 5px ;
    }
    h1 > a {
        width: 140px;
    }
    #headerWrap {
        width: 95%;
    }

    .portHeader {
        background: url(../images/M-port-monitoring.png) no-repeat bottom / cover;
        height: 450px;
        align-items: unset;
    }

    .header > h2 {
        font-size: 1.6rem ;
        width: 85% ;
        margin-top: 130px;
        font-weight: bold;
    }

    .header > h2 > p {
        font-size: .9rem ;
        margin-top: 30px ;
        width: 80%;
        line-height: 1.6rem;
    } 

    #lnb {
        display: none ;
    }

    .Contents {
        width: 350px ;
    }

    .Contents > div {
        margin: 120px 0px;
    }

    .Contents img {
        max-width: 350px ;
    }

    .pageInfo {
        width: 100%;
        font-size: .9rem !important;
        margin: 100px auto !important;
        word-break: keep-all;
    }

    .IcoSec {
        flex-direction: column ;
    }

    .IcoSec > div {
        margin-bottom: 60px;
    }

    .IcoSec > div:last-child {
        margin-bottom: 0;
    }

    .IcoSec > div > img {
        width: 60px;
    }

    .IcoSec > div > p {
        font-size: 1.2rem ;
    }

    .IcoSec > div > p > span {
        font-size: 1rem ;
        line-height: 1.3rem ;
    }

    .portImgSec {
        flex-direction: column;
        align-items: center;
    }

    .portImgSec > div {
        width: 90%;
        margin-bottom: 40px;
    }

    .portImgSec > div:last-child {
        margin-bottom: 0;
    }

    .portImgSec > div > div:first-child {
        height: 50%;
    }

    .portImgSec > div > div:last-child {
        height: 50%;
    } 

    .portImgSec > div > div:last-child > p {
        font-size: 1.2rem;
    }

    .portImgSec > div > div:last-child > span {
        font-size: 1.1rem;
    }

    .UseCase {
        padding: 60px 0px;
        margin: 120px 0px 120px calc(-50vw + 50%) !important;
    }

    .CaseWrap {
        width: 350px;
        padding: 0px 20px;
    }

    .CaseWrap > h3 {
        font-size: 1rem;
    }

    .portCaseWrap > .caseSlide {
        width: 100% ;
        height: 110px ;
        margin: 20px 0px ;
    }

    .portCaseWrap > .caseSlide > img {
        width:  280px ;
    }

    .caseItem > img {
        width: 90px;
    }

    .caseItem > p {
        font-size: .9rem !important;
    }

    .caseItem > div > p {
        font-size: 1.1rem !important;
    }

    .caseItem > div > .line {
        height: 22px !important;
    }

    .ReactModal__Content > img {
        width: 350px ;
    }
}